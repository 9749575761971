.app-calendar{
    display: flex;
}
.app-calendar .app-calendar-date{
    width: 100%;
}
.fc .fc-toolbar.fc-header-toolbar{
    margin-bottom: 0px;
}
.react-datepicker__day--highlighted-week{
    background-color: #01A7A6;
}
.bg-tasks {
    background-color: #3788d8;
    border-color: #3788d8;
}
.bg-transport, .fc-daygrid-dot-event:hover{
    background-color: purple !important;
        border-color: purple !important;
        color: white !important;
}
.bg-estimated-dc {
    background-color: teal;
    border-color: teal;
}



/* naveen css */

.monthly-btn{
    padding: 5px 15px;
    min-width: 115px;
    cursor: pointer;
    font-size: 16px;
    background-color: #f0f0f0;
    background-image: none;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
    font-family: 'SFProDisplay-Medium';
    margin-bottom: 5px;
    border-radius: 25px;
}
.weekly-btn{
    padding: 5px 15px;
    min-width: 115px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
    background-color: #f0f0f0;
    background-image: none;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
    font-family: 'SFProDisplay-Medium';
    margin-bottom: 5px;
    border-radius: 25px;
}
.monthly-btn.active{
    background-color: #F07DA8;
    color: #fff;
}
.weekly-btn.active{
    background-color: #F07DA8;
    color: #fff;
}
.fc{
    background:#fff !important;
        height: calc(100vh - 150px);
}
.tasks-bg .MuiCheckbox-colorSecondary.Mui-checked{
    color: #3788d8  !important;
}
.transpot-bg .MuiCheckbox-colorSecondary.Mui-checked{
    color: purple  !important;
}
.bg-task-complete{
    background-color: gray;
}
.estimated-bg .MuiCheckbox-colorSecondary.Mui-checked{
    color: teal!important;
}
.react-datepicker__header{
    background-color: #01A7A6 !important;
}
.app-calendar .fc .fc-col-header-cell-cushion{
    color: #000;
}
.app-calendar .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: #fff;
}
.app-calendar .react-datepicker__day-name {
    color: #fff;
}
.react-datepicker__day--highlighted-event{
    font-weight: bolder !important;
}
.app-calendar .fc-daygrid-day-frame.fc-scrollgrid-sync-inner a{
    color: #000;
}
.app-calendar .app-calendar-date{
    padding-left: 15px;
}
.calendar-title{
    margin-top: 10px;
    margin-bottom: 20px;
}
.select__control{
        border: 1px solid #CCCCCC !important;
            border-radius: 4px !important;
            box-shadow: 2px 2px 4px #4c4c4c !important;
            font-size: 12px !important;
            background-color: #fff !important;
            color: #000000 !important;
            padding: 0px 14px 0 5px !important;
            line-height: 20px !important;
}
.select__menu {
    margin: 0.125rem auto !important;
}
.select__menu-list{
 box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
}
.select__option {
    background-color: white !important;
    font-size: 0.875rem !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    font-family: 'SFProDisplay-Regular' !important;
}
.select__indicator-separator{
    display: none;
  
}
.select__indicator{
    padding: 0 !important;
}
.select__placeholder{
    font-size: 12px;
    color: #000000 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    
}
.select__option--is-focused {
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 0 0 / 40%) !important;;
    background-color: #757575 !important;;
    color:#fff !important;
    position: relative;
}
.select__option--is-selected{
    background-color: #01A7A6 !important;
    color: #fff !important;
}

.select__group {
    padding:  0 !important; 
}

.select__menu-portal {
    border: 1px solid darkblue !important;
}












@media only screen and (max-width:1199px){
    .weekly-btn{
        min-width: 95px !important;
    }
    .monthly-btn{
        min-width: 95px !important;
    }
}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}




.tooltip {
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 200px;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-margin: ;
    --bs-tooltip-font-size: 0.875rem;
    --bs-tooltip-color: #fff;
    --bs-tooltip-bg: #000;
    --bs-tooltip-border-radius: 0.375rem;
    --bs-tooltip-opacity: 0.9;
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    z-index: var(--bs-tooltip-zindex);
    display: block;
    padding: var(--bs-tooltip-arrow-height);
    margin: var(--bs-tooltip-margin);
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-tooltip-font-size);
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
    bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
    border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    left: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
    border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
    top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    right: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: var(--bs-tooltip-color);
    text-align: center;
    background-color: var(--bs-tooltip-bg);
    border-radius: var(--bs-tooltip-border-radius);
}
.react-datepicker__day--today{
    font-weight: inherit;
}
.fc-event-title{
    white-space: normal;
}
.fc-event-main-frame{
    flex-direction: row !important;
}