 /* adding custom fonts */
 
 @font-face {
    font-family: 'SFProDisplay-Regular';
    src: url('../fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProDisplay-Regular.otf') format('opentype'), url('../fonts/SFProDisplay-Regular.woff') format('woff'), url('../fonts/SFProDisplay-Regular.ttf') format('truetype'), url('../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay-Medium';
    src: url('../fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProDisplay-Medium.otf') format('opentype'), url('../fonts/SFProDisplay-Medium.woff') format('woff'), url('../fonts/SFProDisplay-Medium.ttf') format('truetype'), url('../fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay-Semibold';
    src: url('../fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProDisplay-Semibold.otf') format('opentype'), url('../fonts/SFProDisplay-Semibold.woff') format('woff'), url('../fonts/SFProDisplay-Semibold.ttf') format('truetype'), url('../fonts/SFProDisplay-Semibold.svg#SFProDisplay-Semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay-Bold';
    src: url('../fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProDisplay-Bold.otf') format('opentype'), url('../fonts/SFProDisplay-Bold.woff') format('woff'), url('../fonts/SFProDisplay-Bold.ttf') format('truetype'), url('../fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay-Light';
    src: url('../fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProDisplay-Light.otf') format('opentype'), url('../fonts/SFProDisplay-Light.woff') format('woff'), url('../fonts/SFProDisplay-Light.ttf') format('truetype'), url('../fonts/SFProDisplay-Light.svg#SFProDisplay-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

html {
    font-family: 'SFProDisplay-Regular';
}

*:focus {
    outline: none !important;
}

select:focus {
    outline: none !important;
}

button:focus {
    outline: none !important;
}

button::-moz-focus-inner {
    border: 0 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #01a7a6 !important;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: 0px auto -webkit-focus-ring-color !important;
    outline: none !important;
    outline-offset: unset !important;
}

.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
}
/* body::-webkit-scrollbar {
   width: 1em;
}

body::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
 background-color: darkgrey;
 outline: 1px solid slategrey;
} */
/* .admin-panel-cover .Dashboard-mainPanel-2 {
   width: calc(100% - 293px) !important;
} */

header {
    padding: 0px 0 !important;
    min-height: 50px !important;
    padding-top: 0px !important;
    border-radius: 0 !important;
}

.user-menu-box>ul {
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    color: #333333;
    font-family: 'SFProDisplay-Regular' !important;
    min-width: 125px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.user-menu-box {
    border-radius: 8px !important;
}

.user-menu-box>ul li {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
}

.user-menu-box>ul li .menu-a {
    font-size: 0.875rem;
    line-height: 16px;
    color: #333333 !important;
    font-family: 'SFProDisplay-Regular' !important;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}

.user-menu-box>ul li .menu-a>span {
    line-height: 16px;
    margin-right: 10px;
}

.user-menu-box>ul li .menu-a i {
    font-size: 16px;
}

.user-menu-box>ul li:not(:last-child) {
    border-bottom: 1px solid #CCCCCC;
}

.user-menu-box>ul li:hover {
    background-color: #01A7A5;
}

.user-menu-box>ul li .menu-a:hover #user-circle_2_ {
    fill: #FFF !important;
}

.user-menu-box>ul li .menu-a:hover {
    color: #FFF !important;
    fill: #FFF !important;
}

.user-menu-box>ul li:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.user-menu-box>ul li:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.ref-scrollbox-1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 156px) !important;
}

.reporter-menu {
    display: flex;
    flex-direction: column;
}

.header-submenu {
    width: 100%;
}

.header-submenu li {
    font-size: 0.875rem;
    line-height: 16px;
    color: #333333 !important;
    font-family: 'SFProDisplay-Regular' !important;
    display: flex;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 2.4rem !important;
    padding-right: 1rem;
    width: 100%;
    border-top: 1px solid #CCCCCC;
    border-bottom: 0px solid #CCCCCC !important;
    border-radius: 0 !important;
}

.header-submenu li:first-child {
    border-top: 1px solid #CCCCCC;
}

.header-submenu li:hover {
    background-color: #fff !important;
    color: #01A7A5 !important;
}

.front-main-layout {
    background: rgb(240, 237, 244);
    background: linear-gradient(90deg, rgba(240, 237, 244, 1) 0%, rgba(240, 237, 244, 1) 24%, rgba(200, 231, 233, 1) 57%, rgba(138, 222, 218, 1) 100%);
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
}

.front-layout-cover {
    padding: 30px 0px !important;
    margin-top: 30px !important;
    min-height: calc(100vh - 30px) !important;
}

.left-content-header {
    margin-bottom: 7px;
    padding-top: 20px;
}

.divcontainer {
    justify-content: space-between;
    padding-left: 53px;
}

.patient-list-page .list-item-content {
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    border-radius: 8px;
    padding: 13px 15px;
    width: calc(100% - 52px);
    display: inline-flex;
    background-color: #FFFFFF;
    padding-right: 0;
}

.patient-list-page .selectedlist {
    background-color: #E9F3FD;
    border: 2px solid #2680EB;
}

.patient-list-page .list-item-checkbox {
    display: inline-flex;
    padding-right: 10px;
    margin: auto;
}

.patient-list-page .left-content-box {
    width: calc(100% - 234px);
    z-index: 0;
}

.patient-list-page .right-box-cover {
    width: 234px;
}

.patient-list-page .title-box {
    margin-top: auto;
    margin-bottom: auto;
}

.patient-list-page .action-box>div {
    margin-top: auto;
    margin-bottom: auto;
}

.titlename {
    font-size: 1.625rem;
    color: #333333;
    font-family: 'SFProDisplay-Medium';
}

.patient-list-page .show-text {
    font-size: 12px;
    color: #4C4C4C;
    font-weight: normal;
}

.patient-list-page .btn1,
.upload-links .btn1,
.ProviderFind .btn1,
.ProviderInfo .btn1,
.CommentModal-info .btn1,
.cms-links .btn1,
.cms-links .btn2 {
    padding: 0 12px !important;
    line-height: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    text-transform: capitalize !important;
}

.upload-links .btn1 {
    width: 101px;
}

.search-box-cover {
    border: 1px solid #CCCCCC !important;
    border-radius: 4px !important;
    box-shadow: 2px 2px 4px #4C4C4C !important;
    font-size: 12px !important;
    background-color: #fff !important;
}

.patient-list-page .search-box-cover input {
    padding-left: 5px !important;
    border-radius: 4px !important;
}

.patient-list-page .search-box-cover button {
    padding: 0 !important;
}

.patient-list-page .left-content-header>div {
    margin-bottom: 0;
}

.patient-list-page .name-status-task-insurance {}

.patient-list-page .name {
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 24ch;
}

.right-box-cover .patientName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 18ch;
    display: block;
}

.patient-list-page .name span {
    vertical-align: text-top;
    margin-left: 2px;
    display: inline-flex;
}

.patient-list-page .date-insurance {
    font-size: 12px;
    line-height: 20px;
    color: #333;
}

.patient-list-page .date-insurance svg {
    font-size: 12px;
    color: #333;
    margin-right: 6px;
}

.patient-list-page .MuiSelect-iconOutlined {
    right: 0;
}

.filter-label .MuiOutlinedInput-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 101px;
    display: block;
}

.tag {
    font-size: 10px;
    color: #000000;
    border-radius: 20px;
    line-height: 20px;
    width: 90px;
    margin-bottom: 5px;
    text-align: center;
    letter-spacing: 0.5px !important;
    font-family: 'SFProDisplay-Regular' !important;
}

.tag.mr-10 {
    margin-right: 10px;
}

.Awaiting {
    background-color: #7DBDD9 !important;
}

.Finding {
    background-color: #D5C7E1 !important;
}

.Finalized {
    background-color: #01A7A5 !important;
}

.Accepted {
    background-color: #01A7A6 !important;
    color: #FFFFFF !important;
}

.Pending {
    background-color: #1B9DD7 !important;
}

.Declined {
    background-color: #FF0000 !important;
    color: #FFFFFF !important;
}

.Decline {
    background-color: #FF0000 !important;
    color: #FFFFFF !important;
}

.Dropped {
    background-color: #CCCCCC !important;
}

.ACKNOWLEDGE,
.Acknowledged {
    background-color: #1fd4d2 !important;
    font-size: 10px;
}

.tag.Planning,
.tag.noStatus,
.tag.Newly {
    border: 1px solid #7DBDD9;
    background: #7DBDD9;
}

.tag.Planned {
    border: 1px solid #01A7A5;
    background: #01A7A5;
    color: #fff;
}

.tag.Discharged {
    border: 1px solid #CCCCCC;
    background: #CCCCCC;
    color: #4C4C4C;
}

.tag.Inactive {
    border: 1px solid #7F7F7F;
    background: #fff;
    color: #7F7F7F;
}

.tag.Issue {
    border: 1px solid #FF0000;
    background: #FF0000;
    color: #fff;
}

.tag.task1 {
    border: 1px solid #7DBDD9;
    background: #7DBDD9;
}

.tag.task2 {
    border: 1px solid #01A7A5;
    background: #01A7A5;
    color: #F8F8F8;
}

.patient-list-page .arrow-next {
    box-shadow: 5px 2px 4px #4C4C4C;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: #FFFFFF;
    margin-right: -7px;
    margin-left: 20px;
}

.patient-list-page .arrow-prev {
    box-shadow: 5px 2px 4px #4C4C4C;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: #FFFFFF;
    margin-right: 20px;
    margin-left: 35px;
}

.patient-list-page .owl-carousel {
    position: relative;
    padding: 0 40px;
}

.patient-list-page .owl-prev {
    position: absolute;
    left: -10px;
    top: calc(50% - 10px);
    color: #4c4c4c !important;
    /* box-shadow: 3px 2px 4px #4C4C4C; */
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: #FFFFFF !important;
}

.patient-list-page .owl-next {
    position: absolute;
    right: -10px;
    top: calc(50% - 10px);
    color: #4c4c4c !important;
    /* box-shadow: 3px 2px 4px #4C4C4C; */
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: #FFFFFF !important;
}

.patient-list-page .owl-nav .fa {
    font-size: 12px;
    vertical-align: middle;
    margin-top: -4px;
    color: #333;
}

.patient-list-page .MuiCheckbox-colorSecondary.Mui-checked {
    color: #01A7A6 !important;
}

.patient-list-page ::placeholder {
    color: #B2B2B2 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.patient-list-page .filter-select .MuiSelect-select {
    border: 1px solid #CCCCCC !important;
    border-radius: 4px !important;
    box-shadow: 2px 2px 4px #4C4C4C !important;
    font-size: 12px !important;
    background-color: #fff !important;
    color: #000000 !important;
}

.patient-list-page .box-filter {
    width: 140px;
}

.patient-list-page .filter-orderby {
    width: 180px;
}

.patient-list-page .box-filter .MuiInputLabel-animated {
    transform: translate(14px, 10px) scale(1) !important;
}

.patient-list-page .box-filter .MuiInputLabel-shrink {
    transform: translate(2px, -14px) scale(0.75) !important;
    color: #000 !important;
}

.patient-list-page .box-filter .MuiSelect-select:focus {
    background-color: #fff !important;
}

.patient-list-page .box-filter .MuiOutlinedInput-input {
    padding: 0px 14px 0 5px !important;
    line-height: 30px !important;
}

.patient-list-page .box-filter .MuiFormGroup-root {
    margin-bottom: 0px !important;
}

.front-modal {
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
}

.front-modal .add-modal-title {
    background-color: #01A7A6;
    border-bottom: 1px solid #01A7A6;
    position: relative;
    color: #FFFFFF;
    padding: 12px 16px;
}

.front-modal .add-modal-title button {
    top: 1px !important;
}

.front-modal .MuiTypography-h6 {
    color: #FFFFFF;
    font-size: 1rem;
    font-family: 'SFProDisplay-Medium';
}

.front-modal .closeButton svg path {
    fill: #FFFFFF;
}

.front-modal .MuiInput-formControl {
    box-shadow: 2px 2px 4px #333333;
    border: 1px solid #CCCCCC;
    margin-top: 20px;
}

.front-modal .MuiInput-underline::after {
    transform: none !important;
    border-bottom: 0px solid #949494 !important;
}

.front-modal .MuiInput-underline::before {
    border-bottom: 0px solid #d2d2d2 !important;
}

.front-modal .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.front-modal .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.front-modal .MuiInput-input {
    padding-left: 8px !important;
}

.front-modal .MuiInputLabel-animated {
    left: 8px !important;
    font-weight: 400 !important;
    color: #333333 !important;
    transform: translate(0, 30px) scale(1);
}

.front-modal .MuiInputLabel-shrink {
    left: 0px !important;
    font-weight: 400 !important;
    color: #333333 !important;
    transform: translate(0, 1.5px) scale(1);
    transform-origin: top left;
}

.front-modal .MuiFormLabel-root {
    font-size: 13px !important;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: 400 !important;
    color: #333333 !important;
}

.front-modal .modal-actions {
    margin-top: 0 !important;
}

.front-modal .error-class {
    padding-top: 4px !important;
    line-height: 1 !important;
}

.front-modal .modal-actions .btn1 {
    padding: 0 30px !important;
    line-height: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
    color: #FFFFFF !important;
}

.front-modal .cancel-link .MuiButton-label {
    color: #666666 !important;
}

.front-modal .cancel-link {
    border-radius: 20px !important;
    text-transform: capitalize;
    line-height: 28px;
    min-width: 100px;
}

.chipinput {
    margin-right: 12px !important;
    box-shadow: 2px 2px 4px #333333 !important;
    border: 1px solid #B2B2B2 !important;
    margin-bottom: 1rem !important;
    background-color: #FFFFFF !important;
    color: #999999 !important;
    width: 126px !important;
    justify-content: start;
}

.chipinput.activeCat {
    color: #01A7A6 !important;
    border: 1px solid #01A7A6 !important;
}

.chipinput.activeCat svg path {
    fill: #01A7A6 !important;
}

.chipinput.inactiveCat {
    color: #999999 !important;
    border: 1px solid #999999 !important;
}

.chipinput.inactiveCat svg path {
    fill: #999999 !important;
}

.MuiChip-icon {
    padding: 0 !important;
}

.front-modal .form-caption {
    color: #000000 !important;
    font-family: 'SFProDisplay-Semibold' !important;
    margin-bottom: 1rem;
    font-size: 1rem !important;
}

.front-modal .form-label {
    color: #000000;
    font-family: 'SFProDisplay-Regular' !important;
    font-size: 13px;
    margin-bottom: 5px;
}

.MuiChip-root {
    justify-content: start !important;
}

.front-modal .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
}

.files-dropzone-list {
    display: inline-block;
}

.pos-rel {
    position: relative;
}

.info-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
    background-color: #01A7A6 !important;
}

.btn1 .MuiTypography-colorPrimary {
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.no-data-div {
    display: flex;
    justify-content: center;
    height: calc(100vh - 200px);
}

.no-data-div>div {
    margin: auto;
    font-size: 2rem;
}

.patient-list-page .list-scroll {
    width: calc(100% - 532px) !important;
    margin: auto;
}

.owl-item-box {
    cursor: pointer;
    border: 1px solid #CCCCCC !important;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    max-width: 108px;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: 400;
}

.name-status-task-insurance {
    cursor: pointer;
    width: 200px;
}

.category-value-status-list {
    min-width: 152px;
    width: calc(100% - 200px);
}

.category-value-status-list .owl-carousel .owl-item {
    width: 112px !important;
    min-width: 112px
}

.AwaitingProviderTabPanelCSS .OwlCarousel-box {
    min-width: 152px;
    width: calc(100% - 200px);
}

.AwaitingProviderTabPanelCSS .owl-carousel .owl-item {
    /* width: 112px !important; */
}

.AwaitingProviderTabPanelCSS .title-box {
    margin-top: 0;
    margin-bottom: 0;
}

.AwaitingProviderTabPanelCSS .title-box .label {
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: 1px !important;
    font-family: 'SFProDisplay-Medium';
    font-weight: normal;
}

.AwaitingProviderTabPanelCSS .title-box .value {
    color: #FFFFFF;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Medium';
    font-weight: normal;
}

.AwaitingProviderTabPanelCSS .phoneNumber {
    vertical-align: super;
}

.AwaitingProviderTabPanelCSS .tag {
    font-size: 0.875rem;
    color: #000000;
    border-radius: 6px;
    line-height: 34px;
    width: 70px;
    text-align: center;
    margin: 0 0px;
    letter-spacing: 0.5px !important;
    font-family: 'SFProDisplay-Regular' !important;
}

.AwaitingProviderTabPanelCSS .list-item-content-data {
    justify-content: space-between;
}

.AwaitingProviderTabPanelCSS .menu-item {
    width: 100%;
    max-width: 160px;
    box-shadow: 4px 4px 6px #4c4c4c;
    border-radius: 8px;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #CCCCCC !important;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: 400;
}

.AwaitingProviderTabPanelCSS .category-type {
    padding: 8px 0px;
    text-align: center;
    margin: auto;
    color: #000000;
    font-size: 13px;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    /* min-width: 112px; */
    white-space: nowrap;
}

.AwaitingProviderTabPanelCSS .amount-value {
    padding: 4px 0px;
    font-size: 12px;
    line-height: 16px;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    text-transform: capitalize;
    color: #000000;
    background-color: #E9F3FD;
    border-top: 1px solid #CCCCCC;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.category-value {
    color: #000000;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    padding: 8px 0px;
}

.category-status {
    padding: 4px 0px;
    font-size: 12px;
    line-height: 16px;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    text-transform: capitalize;
    color: #000000;
    background-color: #D5C7E1;
}

.horizontal-menu {
    justify-self: start;
    justify-content: start;
    align-self: start;
    align-items: flex-start;
    margin: auto 0;
}

.right-box-cover {
    padding-top: 20px;
}

.right-box-cover .right-box-content {
    text-align: center;
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    background-color: #FFFFFF;
}

.mb-20 {
    margin-bottom: 20px;
}

.right-box-cover .box-header {
    background-color: #C9B3DA;
    color: #333333;
    padding: 11px 12px;
    font-family: 'SFProDisplay-Medium';
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 16px;
    line-height: 1.5;
}

.right-box-cover .tasks .box-header {
    background-color: #189DD6;
    color: #FFFFFF;
    padding: 4px 12px;
    position: relative;
}

.right-box-cover .middle-section {
    min-height: 282px;
}

.right-box-cover .middle-section-content {
    margin: auto;
}

.right-box-cover .text {
    color: #7F7F7F;
    font-family: 'SFProDisplay-Light';
    max-width: 134px;
}

.categorylist-cover .middle-section {
    justify-content: flex-start;
    text-align: left;
}

.categorylist-cover .middle-section-content {
    width: 100%;
    margin: 0 0;
}

.categorylist {
    padding: 5px 3px 5px 14px;
    color: #333;
    font-size: 14px;
    font-family: 'SFProDisplay-Medium';
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
    background-color: #E9F3FD;
    height: 35px;
}

.categorylist.inactiveCategiry {
    background-color: #FFFFFF;
    color: #999999;
}

.middle-section-content> :last-child>.categorylist,
.middle-section-content> :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.front-main-layout .sorting-items,
.front-main-layout .capmpaign-items {
    padding-left: 16px !important;
}

.no-data-text {
    margin-left: 40px;
    font-size: 1.25rem;
    color: #958e8e;
}

.notes-h {
    font-size: 14px;
    line-height: 1.2;
}

.no-notes {
    font-size: 13px;
    color: #958e8e;
}

.find-provider-tabs .no-found-text {
    margin: 10vh auto;
    font-size: 1.25rem;
    color: #FFF;
    text-align: center;
}

.find-provider-tabs .buttonProgress {
    color: #01A7A6 !important;
    text-align: center !important;
    margin: 10vh !important;
}

.CircularProgress-CSS {
    color: #FFF !important;
    text-align: center !important;
    margin: 10vh !important;
}

.find-provider-tabs .loader {
    margin: 10vh auto;
    font-size: 1.25rem;
    color: #FFF;
    text-align: center;
}
/* .YOnlytScrollbars  div {
   overflow-x: hidden !important;
} */

.MuiDialogContent-root:first-child {
    padding-top: 8px !important;
}

.sorting-items,
.capmpaign-items {
    padding-left: 16px !important;
}

.MuiDialog-paperScrollPaper {
    max-height: unset;
}

.MuiDialog-paper form,
.front-modal .MuiInputBase-root {
    font-family: 'SFProDisplay-Regular' !important;
}

.MuiMenuItem-root {
    font-size: 0.875rem !important;
    font-family: 'SFProDisplay-Regular' !important;
}

.react-tabs__tab-panel {
    background-color: #51C1C0;
}

.btn4 {
    background-color: #E9F3FD !important;
    padding: 0 20px !important;
    margin-right: 0px !important;
    border-radius: 20px !important;
    /* box-shadow: 2px 2px 4px #4C4C4C !important; */
    line-height: 38px !important;
    height: 38px !important;
    min-width: 124px !important;
    border: 1px solid #999999 !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-family: 'SFProDisplay-Regular' !important;
}

.btn4:hover,
.btn4:focus {
    background-color: #E9F3FD !important;
    border: 1px solid #4C4C4C !important;
}

.btn4:disabled {
    background: #dddddd !important;
    cursor: not-allowed;
}

.find-provider-tabs .patient-list-page .left-content-box {
    width: 100%;
}

.left-tabs {
    width: 240px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    border-bottom: 0;
    box-shadow: 2px 2px 4px #4C4C4C;
    align-self: flex-start;
}

.tab-link {
    color: #333333;
    font-family: 'SFProDisplay-Semibold';
    border-bottom: 1px solid #CCCCCC !important;
    padding: 1rem 0 1rem 2rem;
    cursor: pointer;
}

.tab-link.active,
.tab-link.inactive:hover {
    background-color: #01A7A6;
    color: #FFFFFF;
}

.tab-link.inactive,
.tab-link.active:hover {
    background-color: #FFFFFF;
    color: #333333;
}

.tab-link:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tab-link:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tab-link-3 button {
    margin: 0 0;
    padding: 0 0;
}

.right-tabs-cover {
    padding-left: 24px;
    width: calc(100% - 240px);
}

.right-tabs-content {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    padding: 1.25rem 1.875rem;
    min-height: calc(100vh - 102px);
    position: relative;
}

.ProviderProfile .tab-heading {
    color: #01A7A6;
    font-family: 'SFProDisplay-Medium';
    font-size: 1.375rem;
}

.ProviderProfile .tab-sub-heading {
    color: #333333;
    font-size: 0.8125rem;
    padding-bottom: 0.5rem;
}

.ProviderProfile .tab-sub-heading span {
    font-family: 'SFProDisplay-Bold';
}

.PaterintCriteria .all-cover {
    justify-content: space-between;
}

.ProviderProfile .setcat {
    max-width: 23%;
    margin: 0 1%;
}

.ProviderProfile .setcat:first-child {
    margin-left: 0;
    margin-right: 2%;
}

.ProviderProfile .setcat:last-child {
    margin-right: 0;
    margin-left: 2%;
}

.ProviderProfile .setcat-criteria {
    max-width: 31.33%;
}

.ProviderProfile .MuiFormLabel-root {
    color: #999999 !important;
    font-size: 13px !important;
}

.ProviderProfile .checkbox-input-cover {
    margin-bottom: 8px;
}

.ProviderProfile .Hospice-cover .checkbox-input-cover,
.ProviderProfile .HomeHealth-cover .checkbox-input-cover,
.PaterintCriteria .Language-cover .checkbox-input-cover,
.PaterintCriteria .Insurance-cover .checkbox-input-cover {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.ProviderProfile .caption-switch {
    justify-content: space-between;
    border-bottom: 2px solid #01A7A6;
}

.ProviderProfile .caption {
    font-size: 1.125rem;
    color: #333333;
    font-family: 'SFProDisplay-Bold';
}

.ProviderProfile .caption-switch>div {
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 6px;
}

.ProviderProfile .rate-text {
    font-size: 0.8125rem;
    color: #333333;
    max-width: calc(100% - 36px);
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;
}

.ProviderProfile .input-text {
    font-size: 0.8125rem;
    color: #333333;
    padding-bottom: 6px;
}

.ProviderProfile .input-box {
    padding-left: 34px;
}

.ProviderProfile .MuiOutlinedInput-notchedOutline {
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #333333;
    border-radius: 2px;
    border-color: #CCCCCC !important;
}

.MuiOutlinedInput-root {
    border-radius: 2px;
}

.ProviderProfile .unit {
    font-size: 0.8125rem;
    color: #333333;
    line-height: 40px;
    padding-left: 7px;
}

.ProviderProfile .checkbox-input-cover .MuiCheckbox-root {
    color: transparent;
}

.ProviderProfile .checkbox-input-cover .MuiIconButton-label {
    box-shadow: 2px 2px 4px #333333;
    width: 18px !important;
    height: 18px;
    border-radius: 2px;
}

.MuiRadio-colorPrimary.Mui-checked {
    color: #01A7A6 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #01A7A6 !important;
}

.MuiCheckbox-colorSecondary.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

.PaterintCriteria .sm-text {
    color: #333333;
    font-size: 12px;
}

.PaterintCriteria .Infectious-cover .form-box {
    padding-left: 12px;
}

.left-list {
    width: 216px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    align-self: flex-start;
}

.right-details-tabpanel {
    padding-left: 16px;
    width: calc(100% - 216px);
}

.left-title-info {
    border-bottom: 1px solid #CCCCCC;
    padding: 0.5rem 1rem;
}

.patient-info-cover {
    padding-top: 1rem;
}

.patient-info-cover .patient-info {
    width: 180px;
    margin: auto;
    margin-bottom: 1.25rem;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 2px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    cursor: pointer;
    position: relative;
}

.patient-info-cover .patient-info.selectedlist {
    background-color: #E9F3FD;
    border: 2px solid #2680EB;
}

.patient-info .name {
    color: #000000;
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Medium';
    padding-left: 1rem;
    padding-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
}

.patient-info .DC {
    color: #000000;
    font-size: 0.875rem;
    padding-left: 1rem;
}

.patient-info .status {
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    text-transform: capitalize;
    color: #000000;
    background-color: #D5C7E1;
    font-size: 0.875rem;
    padding-left: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: 0.5rem;
}

.status.Newly,
.Newly {
    background-color: #7DBDD9 !important;
    color: #000000 !important;
}

.status.Planning,
.Planning {
    background-color: #7DBDD9 !important;
    color: #000000 !important;
}

.status.Planned,
.Planned {
    background-color: #01A7A5 !important;
    color: #FFFFFF !important;
}

.status.Discharged,
.Discharged {
    background-color: #CCCCCC !important;
    color: #000000 !important;
}

.status.Issue,
.Issue {
    background-color: #FF0000 !important;
    color: #E5E5E5 !important;
}

.status.Inactive,
.Inactive {
    background-color: #CCCCCC !important;
    color: #333333 !important;
}

.status.Closed,
.Closed {
    background-color: #CCCCCC !important;
    color: #333333 !important;
}

.details-section {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    /* padding: 0 24px; */
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.multiple-selection {
    background: #E5E5E5;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    padding: 0 12px;
}

.multiple-selection>* {
    align-self: center;
}

.multiple-selection>.fa {
    color: #2680EB;
}

.details-section>.details-all-cover {
    padding: 0 24px;
}

.notes-div {
    font-size: 0.875rem;
    color: #000000;
    background-color: #FFFFFF;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transform: rotate(-90deg);
    width: 88px;
    position: absolute;
    right: -38px;
    top: calc(50% - 21px);
    justify-content: center;
    display: flex;
    height: 43px;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
}

.notes-div img {
    transform: rotate(90deg);
    margin-top: 4px;
}

.details-section .notes-div img {
    margin-left: -10px;
}

.name-status-tag {
    display: flex;
    flex-direction: row;
    min-width: 242px;
    margin-left: auto;
    margin-right: auto;
}

.name-status-tag .name {
    background-color: #7B7979;
    color: #FFFFFF;
    font-size: 1.25rem;
    border-bottom-left-radius: 6px;
    line-height: 31px;
    padding: 0px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 24ch;
}

.name-status-tag .awaitname {
    color: #FFFFFF;
    font-size: 1.25rem;
    margin: 0 auto; /* Center horizontally */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center
}

.name-status-tag .status {
    background-color: #01A7A6;
    color: #FFFFFF;
    font-size: 0.875rem;
    border-bottom-right-radius: 6px;
    line-height: 31px;
    padding: 0px 8px;
}

.ProviderFind a.link-a,
header a {
    display: inline-flex;
}

.ProviderFind .section .btn1 {
    margin-bottom: 10px;
    width: 155px;
    font-size: 13px !important;
}

.ProviderFind .btn1.addbtn {
    width: max-content;
    margin-bottom: 8px;
    margin-left: -5px;
    min-width: 70px !important;
}

.ProviderFind .details-all-cover {
    justify-content: space-between;
}

.ProviderFind .details-info .info-label {
    font-size: 11px;
    line-height: 1.1;
    color: #7F7F7F;
    font-family: 'SFProDisplay-Regular';
    font-weight: normal;
    text-transform: capitalize;
}

.ProviderFind .details-info .info-value {
    font-size: 0.875rem;
    line-height: 1.1;
    color: #000000;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-family: 'SFProDisplay-Regular';
    font-weight: normal;
}

.ProviderFind .tasks-cover {
    flex-flow: wrap;
}

.ProviderFind .task-name {
    border-left: 5px solid #0D81B4;
    line-height: 20px;
    color: #0D81B4;
    margin-bottom: 5px;
    width: 50%;
    
}

.ProviderFind .w-50 {
    width: 50%;
}

.no-tasks {
    font-size: 1rem;
    color: #958e8e;
}

.ProviderFind .task-name span {
    font-size: 0.875rem;
    text-decoration: underline;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
}

.ProviderFind .task-name.Incomplete {
    border-left: 5px solid #0D81B4;
    color: #0D81B4;
}

.ProviderFind .task-name.Complete {
    border-left: 5px solid #01A7A6;
    color: #01A7A6;
}

.ProviderFind .task-name.Block,
.ProviderFind .task-name.Blocked {
    border-left: 5px solid #FF0000;
    color: #FF0000;
}

.ProviderFind .mb-13 {
    margin-bottom: 13px;
}

.ProviderFind .section-heading {
    color: #000000;
    font-family: 'SFProDisplay-Medium';
    text-transform: uppercase;
    line-height: 30px;
}

.ProviderFind .left-content-header .titlename {
    color: #FFFFFF;
    font-family: 'SFProDisplay-Medium';
    font-size: 0.875rem;
}

.ProviderFind .left-content-box .notes-div {
    width: 113px;
    right: -50px;
    top: 98px;
    justify-content: flex-start;
}

.no-tasks.pl-1 {
    padding-left: 1rem !important;
}

.height-SC {
    height: calc(100vh - 420px);
}

.provider-tab {
    display: flex;
    flex-flow: wrap;
    width: 100%;
}

.provider-tab li {
    margin-right: 10px;
    background-color: transparent;
    padding: 10px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 0;
    box-shadow: none;
}

.provider-tab li:hover,
.provider-tab li:focus {
    background-color: #51C1C0;
}

.provider-tab .category-value-status {
    min-width: 108px;
    height: 66px;
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    border-radius: 8px;
    justify-content: space-between;
}

.provider-tab .categoryValue {
    color: #000000;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    margin: auto;
}

.provider-tab .categoryStatus {
    text-align: center;
    color: #000000;
    font-size: 0.75rem;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    background-color: #D5C7E1;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.provider-tab .react-tabs__tab--selected {
    /* padding: 10px 16px; */
    background-color: #51C1C0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 0;
    box-shadow: none;
}

.find-provider-tabs .PatientList .MuiIconButton-label {
    box-shadow: 2px 2px 4px #333333;
    width: 17px !important;
    height: 17px;
    border-radius: 4px;
    background-color: #fff;
    color: #51C1C0;
    color: transparent;
}

.find-provider-tabs .divcontainer-tab {
    justify-content: space-between;
    padding-left: 53px !important;
}

.find-provider-tabs .user-info-tab {
    width: 140px !important;
}

.find-provider-tabs .user-info-tab .name {
    max-width: 14ch;
    margin: 0 0 0 !important;
}

.find-provider-tabs .action-box-tab>div {
    margin-top: auto;
    margin-bottom: auto;
}

.find-provider-tabs .PatientList .MuiCheckbox-colorSecondary {
    color: #fff;
}

.find-provider-tabs .PatientList .MuiCheckbox-colorSecondary.Mui-checked .MuiIconButton-label {
    background-color: #51C1C0;
    color: #fff;
}

.find-provider-tabs .PatientList .MuiCheckbox-colorSecondary.Mui-disabled {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.26) !important;
}

.front-modal .MuiInputBase-input.Mui-disabled {
    background-color: #E5E5E5;
    border-color: #CCCCCC;
    /* color: rgba(0, 0, 0, 0.42); */
    color: rgba(0, 0, 0);
}

.front-modal form,
.front-modal .modal-actions {
    width: 100% !important;
}

/* .user-msg-tab {
    font-size: 0.875rem;
    width: 300px;
    line-height: 1.4;
} */
.user-msg-tab {
   font-size: 0.875rem;
   width: 300px;
   line-height: 1.4;
}
/* ----------- / Provider Tab CSS */
/* PatientStatusDialog CSS */

.add-modal.PatientStatusDialog .MuiDialog-paperWidthSm {
    max-width: 320px;
    width: 100%;
}

.patient-name {
    color: #333333;
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Semibold';
    font-weight: normal;
    text-transform: uppercase;
    margin-top: 0;
}

.comments-list .btn2 {
    padding: 0 8px !important;
    line-height: 26px !important;
    height: 26px !important;
    border-radius: 26px !important;
    text-transform: capitalize !important;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px !important;
    min-width: 70px !important;
}

.PatientStatusDialog .MuiDialogContent-root {
    padding-right: 8px;
}

.comments-list .MuiInput-formControl {
    margin-top: 10px;
}
/* -------------- / PatientStatusDialog CSS */

.add-modal.ProviderInfo .MuiDialog-paperWidthSm {
    max-width: 380px;
    width: 100%;
}

.ProviderInfo .aboutProvider {
    margin-top: 10px;
    word-break: break-all;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Regular';
}

.ProviderInfo .providerName {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Regular';
    font-weight: normal;
}

.ProviderInfo .phoneNumber {
    color: #333333;
    font-size: 0.9375rem;
    font-family: 'SFProDisplay-Semibold';
}

.ProviderInfo .email {
    color: #333333;
    font-size: 0.9375rem;
    font-family: 'SFProDisplay-Semibold';
}

.ProviderInfo .provider-info-div svg {
    vertical-align: middle;
    margin-right: 10px;
}

.ProviderInfo .btn1 {
    min-width: 70px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    cursor: pointer;
}

.ProviderInfo .submitbtn1 {
    min-width: 72px !important;
    line-height: 70px;
}

.ProviderInfo .TermsCheck .MuiCheckbox-root {
    color: transparent;
}

.ProviderInfo .TermsCheck .MuiIconButton-label {
    box-shadow: 2px 2px 4px #333333;
    width: 18px !important;
    height: 18px;
    border-radius: 2px;
}

.ProviderInfo .TermsCheck .MuiFormControlLabel-label {
    font-size: 13px;
    color: #000 !important;
}

p.imageName.imagedAdded,
p.imageName.imageAttached,
p.imageName {
    word-break: break-all;
    line-height: 1;
    font-size: 13px;
    color: #ccc;
}
/* NotesModal */

.add-modal.NotesModal .MuiDialog-paperWidthSm {
    /* max-width: 816px; */
    max-width: 876px;
    width: 100%;
    min-height: 411px !important;
    background-color: #51C1C0;
}

.NotesModal .MuiPaper-rounded {
    border-radius: 8px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.notes-title {
    color: #FFFFFF;
    font-family: 'SFProDisplay-Medium';
    font-size: 1.125rem;
    line-height: 1;
    padding-top: 6px;
}

.ExecutiveSummary .comment-TextField textarea {
    height: 222px;
    background-color: #FCFCFC;
}

.noteinput .comment-TextField textarea {
    height: 54px;
}

.auto-saved {
    color: #FFFFFF;
    opacity: 70%;
    text-align: right;
    font-size: 12px;
    list-style: 16px;
}

.pos-rel.NotesDiv {
    position: absolute;
    right: 16px;
    top: 190px;
}

.NotesModal .MuiDialog-scrollPaper {
    justify-content: flex-end;
    align-items: flex-start;
}

.NotesModal .MuiDialog-paper {
    margin: 0;
    margin-right: 0;
    margin-top: 150px;
}

.change-right-position-notes {
    /* right: 794px !important; */
    right: 854px !important;
    z-index: 9999;
    position: fixed !important;
    top: 172.5px !important;
}

.change-right-position-notes img {
    transform: rotate(-90deg);
    margin-top: 0px;
}
/*   LevelCriteria */

.chip-select .MuiSelect-selectMenu {
    white-space: normal !important;
}

.add-modal.LevelCriteria .chipval {
    height: 20px !important;
    font-size: 10px !important;
}

.add-modal.LevelCriteria .chip-list-div {
    display: flex;
    flex-direction: column;
}

.selected-chip {
    display: flex;
    flex-direction: column;
}

.add-modal.LevelCriteria .MuiDialog-paperWidthSm {
    max-width: 561px;
    width: 100%;
    height: 480px !important;
    background-color: #51C1C0;
}

.LevelCriteria .MuiDialog-scrollPaper {
    justify-content: flex-end;
    align-items: flex-start;
}

.LevelCriteria .MuiDialog-paper {
    margin: 0;
    margin-right: 0;
    margin-top: 365px;
}

.LevelCriteria .MuiPaper-rounded {
    border-radius: 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.LevelCriteria .add-modal-title {
    background-color: #51C1C0;
    border-bottom: 1px solid #51C1C0;
    position: relative;
    color: #FFFFFF;
    padding: 12px 0px;
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Medium';
    letter-spacing: 1px !important;
    font-weight: normal;
}

.LevelCriteria form {
    background-color: #51C1C0;
}

.LevelCriteria .MuiInput-formControl {
    background-color: #FFFFFF;
}

.LevelCriteria .cancel-link .MuiButton-label {
    color: #FFFFFF !important;
}

.LevelCriteria .MuiFormLabel-root {
    letter-spacing: 1.5px !important;
}

.front-modal.LevelCriteria .MuiInputLabel-animated {
    color: #333 !important;
    z-index: 9999 !important;
}

.front-modal.LevelCriteria .MuiInputLabel-shrink {
    color: #FFFFFF !important;
    z-index: 9999 !important;
}

.LevelCriteria .ClearAll-link {
    font-size: 0.875rem;
    text-decoration: underline;
    color: #FFFFFF!important;
    cursor: pointer;
    margin-left: auto !important;
}

.LevelCriteria .CategoryCriteria-text {
    font-family: 'SFProDisplay-Medium';
    letter-spacing: 1px !important;
    color: #FFFFFF!important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

.FilterDiv .notes-div,
.LevelCriteria .notes-div {
    width: 113px;
    right: -50px;
    top: -51px;
    justify-content: flex-start;
}

.MuiTooltip-tooltip {
    background-color: #FFFFFF !important;
    color: #333 !important;
    box-shadow: 2px 2px 4px #4C4C4C !important;
    font-size: 12px !important;
    width: 170px !important;
}

.change-right-position {
    right: 526px !important;
    z-index: 9999;
    position: fixed !important;
    top: 400px !important;
    /* bottom: 520px !important; */
}

.change-right-position img {
    transform: rotate(-90deg);
    margin-top: 0px;
}

.front-modal.LevelCriteria .chipinput {
    width: 100px !important;
}

.chip-title {
    color: #FFFFFF;
    font-size: 12px;
    letter-spacing: 1.5px !important;
    font-weight: normal !important;
    line-height: 20px;
}
/* / LevelCriteria */
/*  AllReferrals-page CSS */

.AllReferrals-page .divcontainer-Ref {
    justify-content: space-between;
    padding-left: 0;
}

.AllReferrals-page .list-item-content-Ref,
.list-item-content-notification {
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    border-radius: 8px;
    padding: 13px 15px;
    width: 100%;
    display: inline-flex;
    background-color: #FFFFFF;
}

.AllReferrals-page .list-item-content-Ref.selected-Ref {
    border: 2px solid #2680EB;
    background-color: #E9F3FD;
}

.AllReferrals-page .list-item-content-Ref {
    margin-bottom: 10px;
}

.AllReferrals-page .name {
    font-family: 'SFProDisplay-Medium';
    font-size: 1.25rem;
    margin-bottom: 0;
}

.AllReferrals-page .name.id-name {
    overflow: visible;
}

.AllReferrals-page .AllRef-content {
    /* justify-content: space-between; */
}

.AllReferrals-page .AllRef-content>div {
    /* margin-top: auto; */
    margin-bottom: auto;
}

.AllReferrals-page .referral-right-content .referral-right-tags {
    width: 200px;
    justify-content: start;
    flex-flow: wrap;
}

.AllReferrals-page .referral-right-content .patient-info-Ref {
    width: 160px;
}

.CommentModal .Ref-Hospital-Patients {
    max-width: 293px;
}

.AllReferrals-page .Ref-tags {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    max-width: 90px;
}

.AllReferrals-page .hospital {
    color: #333333;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Semibold';
    text-transform: capitalize;
}

.AllReferrals-page .patients-num {
    color: #333333;
    font-size: 12px;
}

.AllReferrals-page .Ref-categorybox {
    border: 1px solid #CCCCCC !important;
    border-radius: 4px;
    text-align: center;
    width: 108px;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: 400;
    background-color: #F8F8F8;
}

.AllReferrals-page .category-value {
    color: #000000;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    padding: 8px 0px;
}

.AllReferrals-page .category-status {
    padding: 4px 0px;
    font-size: 12px;
    line-height: 16px;
    font-family: 'SFProDisplay-Regular' !important;
    font-weight: normal;
    text-transform: capitalize;
    width: 100%;
    max-width: 108px;
    color: #000000;
    background-color: #D5C7E1;
}

.AllReferrals-page .tag-date {
    color: #333333;
    font-size: 12px;
}

.AllReferrals-page .referral-right-box {
    background-color: #51C1C0;
    box-shadow: 2px 2px 4px #4C4C4C;
    border-radius: 8px;
    padding: 20px 20px;
    margin-top: 68px;
}

.AllReferrals-page .select-referral-text,
.NotificationsPage .select-referral-text {
    color: #FFFFFF;
    font-size: 1.875rem;
    line-height: 2.5rem;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.AllReferrals-page .referral-right-header {
    justify-content: space-between;
}

.AllReferrals-page .ref-id {
    font-size: 1.625rem;
    color: #FFFFFF;
    font-family: 'SFProDisplay-Medium';
    font-weight: normal;
    margin: 0px 0 0px;
}

.AllReferrals-page .current-status {
    background-color: #7DBDD9;
    border: 1px solid #FFFFFF;
    display: inline-block;
}

.AllReferrals-page .tag-date-right {
    color: #FFFFFF;
    font-size: 0.875rem;
    display: inline-block;
    margin-left: 10px;
}

.AllReferrals-page .extended-info {
    color: #FFFFFF;
    font-size: 0.875rem;
}

.AllReferrals-page .extended-date {
    color: #FFFFFF;
}

.AllReferrals-page .btn3 {
    padding: 0 26px !important;
    line-height: 40px !important;
    height: 40px !important;
    border-radius: 30px !important;
    cursor: pointer;
    min-width: 150px !important;
    margin-right: 20px !important;
}

.AllReferrals-page .btn3:hover,
.AllReferrals-page .btn3:focus {
    background-color: #01A7A6 !important;
}

.AllReferrals-page .referral-right-actions {
    align-self: baseline;
}

.AllReferrals-page .patient-info-Ref .name {
    font-size: 1.125rem;
    display: inline-block;
    vertical-align: middle;
    max-width: 12ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.AllReferrals-page .info-text {
    font-size: 13px;
    line-height: 1.3;
    margin: 2px 0;
}

.AllReferrals-page .ref-tag {
    border: 1px solid #7F7F7F;
    background: #E9F3FD;
    width: auto;
    min-width: 90px;
    padding: 0 5px;
}

.AllReferrals-page .no-shadow {
    box-shadow: none !important;
}

.AllReferrals-page .commentIcon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.AllReferrals-page .ref-text {
    color: #FFFFFF;
    text-align: right;
}

.AllReferrals-page .part-1 {
    /* min-width: 50%;
   width: 716px; */
    width: 50%;
}

.AllReferrals-page .box-filter-ref .MuiOutlinedInput-input {
    padding: 0px 8px !important;
    line-height: 30px !important;
}

.AllReferrals-page .box-filter-ref {
    width: 80px !important;
}

.AllReferrals-page .box-filter-ref.fr-1 {
    width: 120px !important;
}

.AllReferrals-page .box-filter-ref .MuiInputLabel-animated {
    transform: translate(14px, 10px) scale(1) !important;
}

.AllReferrals-page .box-filter-ref .MuiInputLabel-shrink {
    transform: translate(2px, -14px) scale(0.75) !important;
    color: #000 !important;
}

.AllReferrals-page .box-filter-ref.MuiSelect-select:focus {
    background-color: #fff !important;
}

.AllReferrals-page .box-filter-ref .MuiOutlinedInput-input {
    padding: 0px 14px 0 5px !important;
    line-height: 30px !important;
}

.AllReferrals-page .box-filter-ref .MuiFormGroup-root {
    margin-bottom: 0px !important;
}

.AllReferrals-page .part-2 {
    width: 50%;
    /* max-width: calc(100% - 716px); */
}

.AllReferrals-page .action-box-ref>div {
    margin-top: auto;
    margin-bottom: auto;
}

.AllReferrals-page .Decline-Ref-Form {
    width: 100%;
    margin-top: 20px;
}

.AllReferrals-page .Decline-Ref-Form label {
    color: #FFFFFF !important;
}

.AllReferrals-page .search-box {
    width: 82px;
}

.patientListFilters .search-box {
    width: 150px !important;
}

.Decline-TextField,
.Comment-TextField {
    width: 100%;
}

.Decline-TextField textarea,
.comments-section textarea,
.ExecutiveSummary textarea {
    padding: 10px 10px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4x #4C4C4C;
    color: #000;
}

.Decline-Ref-Form .MuiInput-underline::after,
.comments-section .MuiInput-underline::after,
.ExecutiveSummary .MuiInput-underline::after {
    transform: none !important;
    border-bottom: 0px solid #949494 !important;
    border-color: transparent !important;
}

.Decline-Ref-Form .MuiInput-underline::before,
.comments-section .MuiInput-underline::before,
.ExecutiveSummary .MuiInput-underline::before {
    border-bottom: 0px solid #d2d2d2 !important;
    border-color: transparent !important;
}

.Decline-Ref-Form .MuiInput-underline:hover:not(.Mui-disabled)::before,
.comments-section .MuiInput-underline:hover:not(.Mui-disabled)::before,
.ExecutiveSummary .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.Decline-Ref-Form .MuiInput-underline:hover:not(.Mui-disabled)::before,
.comments-section .MuiInput-underline:hover:not(.Mui-disabled)::before,
.ExecutiveSummary .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.add-modal.ref-decline-modal .MuiDialog-paperWidthSm {
    max-width: 500px;
    width: 100%;
}

.ref-decline-modal .decline-text {
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #CCCCCC !important;
}

.ref-decline-modal .decline-text h2 {
    color: #FF0000;
    max-width: calc(100% - 30px);
}

.ref-decline-modal .closeButton svg path {
    fill: #999999;
}

.ref-decline-modal button {
    text-transform: capitalize;
}

.add-modal.ref-accept-modal .MuiDialog-paperWidthSm {
    max-width: 500px;
    width: 100%;
}

.ref-accept-modal .add-modal-title {
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #CCCCCC !important;
}

.ref-accept-modal .add-modal-title h2 {
    color: #01A7A6;
    max-width: calc(100% - 30px);
    line-height: 1.3;
}

.ref-accept-modal .closeButton svg path {
    fill: #999999;
}

.ref-accept-modal p {
    font-size: 0.9375rem;
}

.ref-accept-modal .btn3 {
    text-transform: capitalize;
    min-width: 150px !important;
    padding: 0 26px !important;
    line-height: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
}

.add-modal.CommentModal .MuiDialog-paperWidthSm {
    max-width: 946px;
    width: 100%;
    border-radius: 8px;
    background-color: #51C1C0;
}

.add-modal.CommentModal .CommentModal-title {
    color: #FFFFFF;
}

.CommentModal-info>div {
    padding-right: 50px;
}

.CommentModal .ref-info,
.notification-header-info .ref-info {
    font-size: 12px;
    font-family: 'SFProDisplay-Medium';
    letter-spacing: 1.5px !important;
    font-weight: normal !important;
    line-height: 1;
}

.CommentModal .ref-val,
.notification-header-info .ref-val {
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Medium';
    letter-spacing: 1px !important;
    font-weight: normal !important;
}

.CommentModal .btn1,
.NotesModal .btn1 {
    padding: 0 12px !important;
    line-height: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    text-transform: capitalize !important;
}

.CommentModal .ref-tag:not(:last-child) {
    margin-bottom: 10px;
}
/* /AllReferrals-page CSS */

.btn5 {
    background-color: #FF0000 !important;
    box-shadow: 2px 2px 4px rgb(51, 51, 51) !important;
    min-width: 150px !important;
    padding: 0 26px !important;
    line-height: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
    color: #FFFFFF !important;
    cursor: pointer;
}

.btn5 .MuiButton-label {
    color: #FFFFFF !important;
}

.btn5:hover,
.btn5:focus {
    background-color: #FF0000 !important;
}

.AwaitingTab .divcontainer-tab {
    padding-left: 0 !important;
}

.FindProviderTab .box-filter .MuiInputLabel-shrink {
    color: #FFFFFF !important
}
/* FinalisedTab CSS */

.FinalisedTab .p-right {
    padding-right: 15px !important;
}

.FinalisedTab .divcontainer-tab {
    padding-left: 0 !important;
}

.FinalisedTab .user-info-tab-finalised {
    min-width: 200px !important;
}

.FinalisedTab .OwlCarousel-box-finalised {
    width: calc(100% - 496px);
}

.FinalisedTab .tag-finalised {
    width: 290px;
    margin-top: auto;
    margin-bottom: auto;
}

.FinalisedTab .tag-referral {
    border-radius: 6px;
    background-color: #01A7A6;
    color: #FFFFFF;
    font-size: 0.875rem;
    text-align: center;
    padding: 8px 10px;
}
/* FinalisedTab CSS */
/* Send Referral Confirmation Modal */

.providerLisingMain>.MuiBox-root {
    padding: 10px;
    background-color: #FFF;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #333333;
}

.providerLisingMain .date-insurance {
    display: flex;
    align-items: center;
    word-break: break-all;
    word-break: break-word;
}

.providerLisingMain .date-insurance svg {
    margin-right: 8px;
}

.providerLisingMain .date-insurance .phoneNumber {
    color: #000000;
    font-size: 13px;
}

.providerLisingMain .name {
    padding-bottom: 2px;
    font-family: 'SFProDisplay-Medium';
    color: #000000;
}

.sendReferralConfirmationModal .MuiDialog-paperWidthXs {
    max-width: 320px;
    width: 100%;
}

.sendReferralConfirmationModal .name-status-tag .name {
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Semibold';
    color: #333333;
    background-color: transparent;
    padding: 0;
    margin: 0;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 10px;
}

.sendReferralConfirmationModal .PatientList>p {
    font-size: 13px;
    margin: 10px 0 5px;
}

.type {
    font-size: 13px;
    line-height: 1;
    margin-top: 10px;
}

.type-state {
    font-size: 14px;
    font-family: 'SFProDisplay-Medium';
}

.sendReferralConfirmationModal .providerLisingMain {
    padding-right: 16px;
}

.sendReferralConfirmationModal .MuiDialogContent-root {
    padding-right: 8px;
}

.profile-content .MuiBackdrop-root,
.logout-section .MuiBackdrop-root {
    background-color: #77cacd !important;
    background: linear-gradient(90deg, rgba(240, 237, 244, 1) 0%, rgba(240, 237, 244, 1) 24%, rgba(200, 231, 233, 1) 57%, rgba(138, 222, 218, 1) 100%);
}

.profile form>div {
    margin: 1rem 0;
}
/* Notifications CSS */

.notifications-left-list {
    width: 354px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CCCCCC;
    box-shadow: 2px 2px 4px #4C4C4C;
    align-self: flex-start;
}

.notifications-right-details {
    padding-left: 16px;
    width: calc(100% - 354px);
}

.notifications-details-section {
    background-color: #51C1C0;
    border-radius: 8px;
    padding: 1rem 1rem;
}

.notification-list-content {
    border-bottom: 1px solid #999999;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.notification-title {
    font-family: 'SFProDisplay-Bold';
    line-height: 1.3;
}

.notification-user {
    font-family: 'SFProDisplay-Bold';
}

.notification-user img {
    margin-right: 10px;
}

.notification-status-date {
    justify-content: space-between;
}

.notification-status {
    font-family: 'SFProDisplay-Bold';
}

.Accepted-clr {
    color: #01A7A6;
}

.Declined-clr {
    color: #FF0000;
}

.Finalized-clr {
    color: #01A7A5;
}

.Received-clr {
    color: #01A7A6;
}

.Dropped-clr {
    color: #B2B2B2;
}

.notification-date {
    color: #666666;
    font-size: 0.875rem;
    font-family: 'SFProDisplay-Light';
    text-align: right;
}

.notification-header-info {
    justify-content: space-between;
    color: #FFFFFF;
}

.notification-header-info>div {
    /* align-self: center; */
}

.notification-header-info-1>div {
    padding-right: 50px;
}

.notification-info-status {
    border-radius: 6px;
    padding: 6px 6px;
    font-size: 0.875rem;
    color: #FFFFFF;
    background-color: #01A7A6;
    margin-top: 0;
    margin-bottom: auto;
}

.list-item-content-notification {
    justify-content: space-between;
}

.user-info-notification .date-insurance {
    font-size: 12px;
    line-height: 20px;
    color: #333;
}

.user-info-notification .date-insurance svg {
    font-size: 12px;
    color: #333;
    margin-right: 6px;
}
/* / Notifications CSS */
/*  comments-section-css */

.comments-section .heading {
    color: #FFFFFF;
    font-family: 'SFProDisplay-Medium';
    font-size: 1.125rem;
}

.btn1.mr-10 {
    margin-right: 10px !important;
}

.comments-section .btn1 i {
    margin-right: 5px;
}

.comments-section table {
    border-spacing: 0;
    width: 100%;
}

.comments-section td {
    border: none;
    padding: 8px;
    font-size: 0.875rem;
    line-height: 1.2;
    vertical-align: top;
}

.comments-section td:nth-child(3) {
    width: 30px;
    font-size: 12px;
}

.comments-section td:first-child {
    width: 125px;
}

.comments-section td:last-child {
    width: 153px;
}

.commentdescription {
    padding-right: 16px;
    min-width: 140px;
    /* word-break: break-all; */
}

.comments-section tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.comments-section tr:nth-child(even) {
    background-color: #DCF2F2;
}
/* .comments-section tr:hover {background-color: #DCF2F2;} */

.no-td {
    width: 100%;
}

.comment-title {
    font-family: 'SFProDisplay-Medium';
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.comment-info-box .comment-info-user {
    background-color: #F1F1F1;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-family: 'SFProDisplay-Medium';
    color: #333333;
    padding: 12px 15px;
    width: 182px;
    display: inline-block;
    vertical-align: top;
}

.comment-info-box .comment-info-text {
    background-color: #FFFFFF;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #333333;
    font-size: 0.875rem;
    padding: 12px 20px;
    display: inline-block;
    width: calc(100% - 186px);
}

.comments-section .MuiInput-formControl,
.ExecutiveSummary .MuiInput-formControl {
    margin-top: 0;
    padding-top: 0;
}

.comments-section .MuiInput-formControl,
.ExecutiveSummary .MuiInput-formControl {
    box-shadow: none;
    border: 0;
}

.btn1.setbtnpos {
    margin-left: 10px !important;
    align-self: flex-end;
    box-shadow: none !important;
    margin-bottom: 6px !important;
}

.comment-textbox {
    width: calc(100% - 110px);
}

.comments-section p {
    line-height: 1;
    font-size: 13px;
    margin-bottom: 2px;
    color: #FFFFFF;
    padding-top: 0px;
}
/* /comments-section-css */

.owl-theme .owl-nav .disabled {
    opacity: 0;
    cursor: default;
}

.HomeLink path {
    fill: #01A7A6;
}

.HomeLink {
    margin-left: 20px;
}

.front-main-layout .admin-h {
    width: 100%;
}

.ProviderHeader .admin-h-menu {
    display: none;
}

.profile-box img {
    display: none;
}

.right-box-cover .right-box-content.categorylist-cover,
.right-box-cover .right-box-content.categorylistBox {
    min-height: 216px;
    border: 2px solid #b7abab;
}
/* Task CSS */

.tasks-cover-all {
    width: calc(68% - 200px);
    max-width: 540px;
    padding-right: 16px;
}

.ProviderFind .details-info>div:first-child {
    min-width: 50%;
}

.ProviderFind .btns-cover {
    justify-content: center;
}

.ProviderFind .btns-cover .btn1 {
    margin-left: auto !important;
    margin-right: auto !important;
}

.task-count {
    position: absolute;
    right: -9px;
    background: #2680EB;
    color: #fff;
    font-size: 10px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    top: -9px;
    z-index: 0;
}

.task-count.toshift {
    right: 11px;
}

.notification-count {
    position: absolute;
    right: -9px;
    background: #FF0000;
    color: #fff;
    font-size: 10px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    top: -9px;
    z-index: 0;
}

.task-for {
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Semibold';
    color: #333333;
    text-transform: uppercase;
    margin-top: 8px;
}

.tasks.add-border .box-header.tsks-h {
    text-align: left;
}

.right-box-content.tasks .selectedtask-name {
    display: inline-block;
    vertical-align: middle;
    font-family: 'SFProDisplay-Regular';
    width: calc(100% - 60px);
    text-align: left;
    font-size: 14px;
}

.right-box-content.tasks .task-add {
    position: absolute;
    right: 0;
    top: 0;
}

.right-box-content.tasks .TasksTitle {
    display: inline-block;
    vertical-align: middle;
}

.task-name-list {
    border-bottom: 1px solid #E5E5E5;
    background-color: #FFFFFF;
    color: #333333;
    font-size: 12px;
    font-family: 'SFProDisplay-Medium';
    padding: 3px 12px;
    display: flex;
    justify-content: space-between;
}

.task-name-list:last-child {
    /* border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px; */
    border-bottom: 0px solid #E5E5E5;
    margin-bottom: 4px;
}

.Incomplete {
    border-left: 5px solid #0D81B4;
}

.Complete {
    border-left: 5px solid #01A7A6;
}

.Block,
.Blocked {
    border-left: 5px solid #FF0000;
}

.add-border {
    border: 2px solid #2680EB !important;
}

.TaskListScroll>div {
    overflow-x: hidden;
}

.details-section-notifications .name-status-tag .name,
.details-section-bundle .name-status-tag .name {
    border-bottom-right-radius: 6px;
}

.details-section-notifications .details-all-cover {
    justify-content: space-between;
}

.selected-notification {
    background-color: #D5F3F3;
}

.selected-notification:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.selected-notification:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.selected-notification:last-child .notification-list-content {
    border-bottom: 0px solid #999999;
}

.list-item-content.p-right {
    width: 100%;
}

.SubscriptionDetailsSection .tab-heading {
    margin-bottom: 20px;
}

.service-info-text p {
    font-size: 13px;
    line-height: 18px;
    font-family: 'SFProDisplay-Bold';
}

.SubscriptionDetailsSection .service-info-subtext p {
    font-size: 13px;
    line-height: 18px;
    font-family: 'SFProDisplay-Semibold';
}

.SubscriptionDetailsSection .FacilitiesSection .tab-heading {
    margin-bottom: 12px;
}

.SubscriptionDetailsSection .custom-input {
    line-height: 30px;
    box-shadow: 2px 2px 4px #333333;
    border: 1px solid #CCCCCC;
    padding-left: 8px;
    margin-top: 7px;
    margin-bottom: 4px;
    padding-right: 32px;
    width: 100%;
}

.FacilitiesSection {
    margin-top: 50px;
    max-width: 400px;
}

.confirm-wrap {
    margin-bottom: 90px;
    font-size: 0.875rem;
}

.SubscriptionDetailsSection.front-modal .MuiInput-formControl {
    margin-top: 10px;
    margin-bottom: 4px;
}

.SubscriptionDetailsSection .cvvimg {
    margin-top: 12px;
}

.SubscriptionDetailsSection label {
    color: #333333;
}

.SubscriptionDetailsSection.front-modal .MuiInput-input {
    max-height: 28px;
    height: 17px;
}

.SubscriptionDetailsSection .CardNumberIcon span {
    position: absolute;
    right: 0;
    color: #01A7A6;
}

.information-tag p {
    font-family: 'SFProDisplay-Bold';
    font-size: 0.9375rem;
}

.information-tag .type-1 {
    color: #333333;
}

.information-tag .failed {
    color: #FF0000;
}

ul.facility-name {
    margin-left: 20px;
}

.cardNumber-input {
    position: relative;
}

.cardtype-icon {
    z-index: 99;
    color: #01A7A6;
    position: absolute;
    right: 10px;
    top: 11px;
}

.EditProviderModal .MuiDialog-paperWidthSm {
    max-width: 690px;
    width: 100%;
}

.category-price .priceinput {
    /* padding-left: 1rem; */
}

.category-price .priceinput .MuiInput-formControl {
    margin-top: 10px !important;
}

.category-price .chipinput {
    margin-top: 10px;
}

.category-price-input .priceinput {
    width: 50% !important;
    width: 160px !important;
}

.category-price-input .chipinput {
    width: 50% !important;
}

.EditProviderModal .heading {
    color: #041527;
    font-size: 1rem;
    font-family: 'SFProDisplay-Medium';
    margin-bottom: 10px;
}

.add-modal.editproviderDialog .MuiDialog-paperWidthSm {
    max-width: 690px;
    width: 100%;
}

.information-tag {
    border-top: 1px solid #9E9E9E;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -1.875rem;
    padding: 1.75rem 1.875rem;
}

.cms-links a {
    color: #333333;
    line-height: 42px;
}

.cms-links a:hover {
    color: #01A7A6;
}

.cms-logo {
    width: 200px;
}

.CMSModal .cms-title {
    font-size: 1.4rem;
    font-family: 'SFProDisplay-Medium';
    text-align: center;
}

.cms-title-cover {
    justify-content: space-between;
}

.cms-title-cover>* {
    margin-top: auto;
    margin-bottom: auto;
}

.cms-link {
    color: #333333 !important;
    cursor: pointer;
    width: 200px;
    text-align: right;
}

.cms-link:hover,
.aggree-link:hover {
    color: #01A7A5 !important;
    text-decoration: none !important;
}

.cms-text p {
    padding: 5px 0;
    font-size: 0.875rem;
}

.cms-text b {
    font-family: 'SFProDisplay-Bold';
    font-weight: normal;
}

.cms-text ol {
    margin-left: 20px;
}

.cms-text a {
    cursor: pointer;
    color: #01A7A5 !important;
}

.aggree-link {
    cursor: pointer;
    color: #333333 !important;
}

.cms-links {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.CMSModal .error-class {
    text-align: left !important;
    font-family: 'SFProDisplay-Bold';
    font-size: 1rem !important;
    width: 100% !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.dob {
    margin-right: 12px;
    font-size: 11px;
}

.date-dc {
    font-size: 11px;
}

.logout-section,
.setZ {
    z-index: 999999 !important;
}

.banner-box {
    width: 282px;
    background-color: #FFA7A7;
    color: #333333;
    border-radius: 2px;
    box-shadow: 2px 2px 4px #333333;
    padding: 10px;
    position: absolute;
    right: 0;
    z-index: 9;
    top: 64px;
    font-size: 13px;
}

.banner-box span {
    font-size: 14px;
    font-family: 'SFProDisplay-Bold';
}

.banner-box.type-1 {
    background-color: #FFA7A7;
}

.commentTableScroll {
    margin-bottom: 20px;
}

.MuiDialog-root {
    z-index: 9999999999;
}

.bundle-info {
    background: #E9F3FD;
    border: 1px solid #7F7F7F;
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 0.875rem;
    color: #000 !important;
}

.bundle-info a {
    color: #000 !important;
}

.bundle-info a:hover {
    color: #333 !important;
}

.bundle-header {
    background: #FFE5EF;
    border-radius: 8px;
    color: #000000;
    opacity: 0.6 !important;
    text-align: center;
    white-space: pre-line;
    font-size: 1.125rem;
    font-family: 'SFProDisplay-Medium';
    padding: 10px 10px;
    margin: 1.25rem auto 0 auto;
    width: 180px;
}

.bundle-header span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16ch;
    display: block;
}

.details-info-bundle {
    justify-content: space-between;
}

.bundle-address {
    min-width: auto !important;
}

.details-info-bundle .svgi {
    align-self: self-start;
    margin-top: 2px;
}

.details-section-bundle .name-status-tag,
.details-section-notifications .name-status-tag {
    min-width: auto;
}

.bundle-cover {
    box-shadow: 2px 2px 4px #4C4C4C;
    border-radius: 8px;
    padding: 10px 0;
}

.no-found-text-bundle {
    color: #fff;
    margin: 5vh auto;
}

.bundle-type-patients {
    justify-content: space-between;
}

.name-bundle-list .name {
    font-family: 'SFProDisplay-Semibold';
    font-size: 1.125rem;
    text-transform: uppercase;
}

.bundle-box .name-bundle {
    font-family: 'SFProDisplay-Semibold';
    font-size: 1.125rem;
    text-transform: capitalize;
}

.bundle-box {
    justify-content: flex-start !important;
}

.bundle-box .user-info-tab {
    width: 180px !important;
}

.react-tabs__tab:focus:after {
    background: transparent;
}

.mb-0 {
    margin-bottom: 0;
}

.commingSoonImg {
    max-height: calc(100vh - 174px);
    width: auto;
    margin: auto;
}

.LinearProgress-ref {
    height: 10px;
}

.org-form-cover>div>div {
    margin-bottom: 10px;
}

.org-form-cover .MuiFormControl-root {
    min-width: 90%;
}

.heading-line {
    font-family: 'SFProDisplay-Medium';
    line-height: 14px;
}

.settop {
    margin-top: -203px;
}

.settop .MuiFormControl-fullWidth {
    width: 95%;
}

.invite-form .MuiFormControl-root {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
}

.invite-form .inviteSc {
    width: 100%;
}

.invite-form .MuiFormGroup-root {
    margin-bottom: 0px;
}

.ViewInvitemodal .MuiDialogContent-root {
    background-color: #01A7A5;
}

.ViewInvitemodal .w-100 {
    width: 100% !important;
}

.ViewInvitemodal .divcontainer-tab {
    justify-content: space-between;
    margin-top: 20px;
}

.ViewInvitemodal .add-modal-title {
    padding: 24px 24px 12px 24px;
}

.ViewInvitemodal .title-box .label {
    font-size: 13px;
}

.ViewInvitemodal .title-box .value {
    font-size: 15px;
}

.warning-text {
    color: #daa917 !important;
    text-align: left !important;
    line-height: 1.2 !important;
    font-size: 13px !important;
}

.v-top {
    vertical-align: top !important;
}

.mt-5 {
    margin-top: 5px;
}

.mb-24 {
    margin-bottom: 24px;
}

.w-100 {
    width: 100%;
}

.wb {
    word-break: break-all;
}

.mb-20 {
    margin-bottom: 20px;
}

.mr-0 {
    margin-right: 0 !important;
}

.mt-20 {
    margin-top: 20px;
}

.patientlist-scroll.patients-list {
   max-height: 30vh !important;
}
.date-insurance.align-center svg {
   min-width: 11px !important;
   min-height: 11px !important;
}
.date-insurance.align-center {
   display: flex;
   align-items: center;
}
.autherize_session {
   width: 100%;
   min-height: calc(100vh - 60px);
   display: flex;
   align-items: center;
}
.autherize-session-part {
   width: 100%;
   max-width: 500px;
   margin: 0 auto;
       margin-top: 0px;
   min-height: 400px;
   background: #fff;
   padding: 15px;
   border-radius: 7px;
   box-shadow: 0px 5px 5px #012d2d21;
   display: flex;
   align-items: center;
   justify-content: center;
}
.autherize-session{
   width: 100%;
   height: auto;
   float: left;
   text-align: center;
}
.autherize-session p {
   font-size: 18px;
   font-family: 'SFProDisplay-Bold';
   color: #000;
}
.mb-15{
   margin-bottom: 15px;
}

@media (max-width: 1365px) {
    #discharge-planner-list .search-btn {
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
        border: 1px solid #ccc !important;
    }
    .divcontainer {
        justify-content: flex-start;
        flex-flow: wrap;
        padding-left: 53px;
    }
    .action-box>div:first-child {
        padding-left: 0;
    }
    html {
        font-size: 14px;
    }
    .patient-list-page .name-status-task-insurance {}
    .patient-list-page .list-scroll {
        width: calc(100% - 437px) !important;
    }
    .provider-tab li {
        /* padding: 10px 10px; */
    }
    .details-section {
        margin-bottom: 10px;
    }
    .TabScroll {
        height: calc(-497px + 100vh) !important;
    }
    .Scrollbars-patient-info-cover {
        height: calc(-188px + 100vh) !important;
    }
    .AllReferrals-page .search-box {
        width: 164px;
    }
    .pr-sm-0 {
        padding-right: 0 !important;
    }
    .SubscriptionDetailsSection .custom-input {
        margin-top: 10px;
    }
    .cardtype-icon {
        top: 16px;
    }
    .user-msg-tab {
        width: 200px;
    }
}

@media (max-width: 1279px) {
    .AllReferrals-page .divcontainer-Ref {
        flex-direction: column;
    }
    .AllReferrals-page .action-box-ref {
        justify-content: flex-start;
        margin-top: 10px;
    }
    .AllReferrals-page .referral-right-content .AllRef-content {
        /* flex-direction: column; */
    }
    .AllReferrals-page .referral-right-content .AllRef-content>div {}
    .AllReferrals-page .referral-right-content .referral-right-tags {
        width: auto;
    }
    .AllReferrals-page .referral-right-box {
        margin-top: 102px;
    }
    .AllReferrals-page .list-item-content-Ref {
        padding: 8px 10px;
    }
    .AllRef-content>div {
        /* width: unset;
       flex-basis: max-content; */
    }
    .AllReferrals-page .Ref-Hospital-Patients {
        min-width: 150px;
    }
    .patientlist-scroll {
        height: calc(-183px + 100vh) !important;
    }
}

@media (max-width: 1199px) {
    .tasks-cover-all {
        width: 36%;
    }
    .ProviderFind .task-name {
        width: 100%;
    }
    .patientlist-scroll {
        height: calc(-199px + 100vh) !important;
    }
    .add-modal.NotesModal .MuiDialog-paperWidthSm {
        max-width: 700px;
    }
    .change-right-position-notes {
        right: 677px !important;
    }
    .patient-info-page-new.patient-list-page .view-ref-cover .name {
        margin: 5px auto 0 0;
    }
}

@media (max-width: 1166px) {
    .patient-list-page .action-box {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        width: 100%;
    }
    .patient-list-page .action-box.patientListFilters {
        justify-content: flex-start;
    }
    .ProviderProfile .setcat {
        max-width: 30%;
        width: 33%;
        margin-bottom: 20px;
    }
    .ProviderProfile .all-cover {
        flex-flow: wrap;
    }
    .no-data-text {
        font-size: 1rem;
    }
    .serviceScroll {
        height: calc(-189px + 50vh) !important;
    }
    .comments-section form>div {
        flex-direction: column;
    }
    .comments-section form>div>div {
        width: 90%;
        padding-left: 0;
        padding-right: 0;
        margin-top: 10px;
    }
    .comments-section form>div>div:first-child {
        order: 2;
    }
    .AllReferrals-page .btn3 {
        padding: 0 20px !important;
    }
    .commentTableScroll {
        max-height: calc(100vh - 650px) !important;
    }
    .dateEstablished .MuiInput-formControl {
        width: 90%;
    }
    .AllReferrals-page .name {
        font-size: 1rem !important;
    }
}

@media (min-width: 1000px) and (max-width: 1024px) {
    .AllReferrals-page .Ref-categorybox {
        width: 88px;
    }
    .ref-scrollbox {
        height: calc(-232px + 100vh) !important;
    }
    .ref-scrollbox-1 {
        height: calc(-193px + 100vh) !important;
    }
    .referral-right-content .ref-scrollbox {
        height: calc(-407px + 100vh) !important;
    }
    .AllReferrals-page .search-box {
        width: 140px !important;
    }
    .AllReferrals-page .Ref-categorybox {
        width: 86px;
    }
    .category-value-status-list .owl-carousel .owl-item {
        width: 112px !important;
    }
}

@media(max-width: 1024px) {
    .top_headimport {
        display: flex;
        flex-wrap: wrap;
        justify-content: normal;
    }
    .top_headimport.adjustpadd .searchbyname-md,
    .top_headimport.adjustpadd .box-filter,
    .top_headimport.adjustpadd .MuiBox-root {
        margin-bottom: 15px;
        max-height: 35px;
    }
    
.patientlist-scroll.patients-list {
   max-height: 250px !important;
}
}

@media (max-width: 991px) {
    .custom-container>div.MuiGrid-root,
    .tableResponsive {
        max-width: 100%;
        overflow-x: scroll;
        position: relative;
        z-index: 1;
    }
    .admin-panel-cover .admin-h {
        display: none !important;
    }
    .admin-panel-cover .admin-h-menu svg path {
        fill: #01a7a6;
    }
    .admin-panel-cover .maxmrj_mobile_menu-when {
        background-color: #01a7a6 !important;
        display: none !important;
    }
    .admin-panel-cover .mobile-menu-section {
        display: none;
    }
    .admin-panel-cover .maxmrj_mobile_menu {
        z-index: 99999999;
    }
    .profile-box {
        margin-left: 20px;
        margin-top: 10px;
    }
    .patient-list-page {
        flex-direction: column !important;
    }
    .patient-list-page .left-content-box {
        width: 100%;
    }
    .patient-list-page .right-box-cover {
        max-width: 230px;
        width: 100%;
        margin-left: 42px;
    }
    .provider-profile-page {
        flex-direction: column !important;
    }
    .right-tabs-cover {
        padding-left: 0;
        width: 100%;
        margin-top: 1rem;
    }
    .left-tabs {
        width: 100%;
    }
    .left-tabs>div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .tab-link {
        padding: 1rem 1rem;
        text-align: center;
    }
    .tab-link:last-child {
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 8px;
    }
    .tab-link:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0;
    }
    .provider-find-page {
        flex-direction: column !important;
    }
    .right-details-tabpanel {
        padding-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .left-list {
        width: 100%;
    }
    .patient-info-cover .patient-info {
        margin: auto 10px;
        white-space: nowrap;
    }
    /* .patient-info-cover .patient-info > div {
       padding-right: 1rem;
   } */
    .patient-info-cover {
        flex-direction: row !important;
        justify-content: flex-start !important;
    }
    .Scrollbars-patient-info-cover {
        height: 130px !important;
        width: 100% !important;
    }
    .Scrollbars-bundle-patient-info-cover {
        height: 130px !important;
        width: 100% !important;
    }
    .bundle-header {
        margin-left: 12px;
    }
    .patient-info .name {
        padding-right: 1rem;
    }
    .AllReferrals-page .part-1,
    .AllReferrals-page .part-2 {
        width: 100%;
    }
    .AllReferrals-page .referral-right-box {
        margin-top: 20px;
    }
    .AllReferrals-page .referral-right-content .AllRef-content {
        flex-direction: row;
    }
    .AllReferrals-page .referral-right-content .referral-right-tags {
        max-width: 200px;
    }
    .CommentModal .Ref-Hospital-Patients {
        max-width: 154px;
    }
    .ref-scrollbox {
        height: 300px !important;
    }
    .ref-scrollbox-1 {
        height: calc(50vh - 186px);
    }
    .notifications-page {
        flex-direction: column !important;
    }
    .notifications-left-list {
        width: 100%;
    }
    .notifications-right-details {
        width: 100%;
        margin-top: 20px;
        padding-left: 0;
    }
    .notification-scrollbox {
        max-height: calc(50vh - 100px) !important;
    }
    .patientlist-scroll {
        height: calc(50vh - 100px) !important;
    }
    .right-box-cover .right-box-content {
        min-height: calc(50vh - 139px) !important;
    }
    .right-box-cover .right-box-content .TaskListScroll {
        height: calc(50vh - 176px) !important;
    }
    .right-box-cover .right-box-content .mb-20 {
        margin-bottom: 0px;
    }
    .patient-list-page .right-box-cover {
        display: flex;
        flex-direction: row;
        max-width: calc(100% - 42px);
        justify-content: space-between;
        padding-right: 20px;
    }
    .right-box-cover>div {
        min-width: 48%;
    }
    .MuiDialog-paperWidthMd {
        width: 960px!important;
    }
    .commentTableScroll {
        max-height: calc(50vh - 388px) !important;
    }
    .right-tabs-content {
        min-height: calc(100vh - 167px);
    }
    .banner-box {
        top: 120px;
    }
    .FacilitiesSection-scroll {
        height: calc(-674px + 100vh);
    }
    .invite-tab .tab-link {
        width: 50%;
    }
    div.Sidebar-logoImage {
        width: 120px !important;
    }
    .ProviderHeader .flex-box>div:first-child {
        width: 200px;
    }
    .ProviderHeader .flex-box>div:nth-child(2) {
        width: auto;
        text-align: right;
    }
    .ProviderHeader .flex-box>div:last-child {
        min-width: 50%;
    }
}

@media (max-width: 849px) {
    .patient-list-page .list-item-content>div {
        flex-direction: column;
        justify-content: center;
    }
    .patient-list-page .list-item-content>div>div {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100% !important;
    }
    .tags-cover-box {
        justify-content: center;
    }
    .patient-list-page .list-scroll {
        width: 100% !important;
    }
    .patient-list-page .name {
        max-width: 30ch;
        margin: auto;
    }
    .patient-list-page .name-status-task-insurance,
    .patient-list-page .list-scroll {
        text-align: center;
    }
    .patient-list-page .arrow-prev {
        margin-left: 0;
    }
    .no-data-text {
        margin-left: 0;
    }
    .user-msg-tab {
        text-align: center;
        width: 100%;
    }
    .view-ref-cover .list-item-content-data>div {
        text-align: left !important;
        padding-left: 16px !important;
    }
}

@media (max-width: 767px) {
    .AllRef-content .Ref-Hospital-Patients.comentmodal {
        position: absolute;
        top: 26%;
    }
    .commentTableScroll table {
        min-height: 126px !important;
    }
    .ProviderProfile .setcat {
        max-width: 90%;
        width: 100%;
        margin-bottom: 4rem;
    }
    .ProviderProfile .setcat-criteria {
        max-width: 100%;
        width: 100%;
        margin-bottom: 4rem;
    }
    .patient-list-page .action-box {
        justify-content: center;
        flex-direction: column;
    }
}

@media (max-width: 630px) {
    .patient-list-page .action-box>div {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 10px;
        width: 100%;
    }
    .search-box {
        width: calc(100% - 30px);
    }
}

@media (max-height: 770px) {
    .LevelCriteria .MuiDialog-paper {
        margin-top: calc(100vh - 421px);
    }
    .change-right-position {
        top: calc(100vh - 386px) !important;
    }
    .commentTableScroll {
        /* max-height: 146px !important; */
        max-height: 200px !important;
    }
    .information-tag {
        padding: 10px 1.875rem;
    }
    .notification-loader {
        align-self: start !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .notifications-details-section .notification-scrollbox {
        height: calc(100vh - 330px) !important;
    }
}

@media (max-height: 700px) {
    .comments-section textarea {
        height: 30px;
        padding: 4px 4px;
    }
    .comments-section .btn1 {
        line-height: 24px !important;
        height: 24px !important;
        font-size: 11px !important;
        padding: 0 6px !important;
    }
    .comments-section p {
        padding-top: 4px !important;
    }
    .information-tag p {
        max-width: 50%;
        line-height: 1.2;
    }
    .information-tag {
        border-top: 0px solid #9E9E9E;
    }
    .front-modal .modal-actions .btn1 {
        padding: 0 10px !important;
    }
    .notifications-details-section .notification-scrollbox {
        height: calc(100vh - 200px) !important;
    }
}
/* syed's css start */

@media (width: 768px) {
    .oval_box div {
        float: left;
        display: block;
        margin: 0 auto;
        text-align: center;
        align-items: center;
    }
    .patient-list-page .list-item-content>div>div {
        width: auto !important;
    }
    .category-value-status-list .owl-carousel .owl-item {
        width: 112px !important;
    }
    .tag.task2 {
        margin-left: 20px;
    }
    p.tag.status3.Planning {
        margin-left: 10px;
    }
}

@media (width: 375px) {
    .Ref-categorybox {
        width: 80px !important;
    }
    .Ref-tags {
        position: absolute;
        right: 6px !important;
    }
}

@media (width: 360px) {
    .Ref-categorybox {
        margin-left: -21px;
    }
    .ref-scrollbox-1 {
        height: 195px;
    }
    @media (width: 414px) {
        .ref-scrollbox-1 {
            height: 285px !important;
        }
    }
    .Ref-tags {
        position: absolute;
        right: 14px !important;
        top: 43%;
    }
}

@media (width: 320px) {
    .Ref-categorybox {
        width: 65px !important;
        margin-left: -42px;
    }
    .Ref-tags {
        position: absolute;
        right: 5px !important;
        top: 40%;
    }
}

@media only screen and (max-width: 767px) {
    .list-item-content-Ref.no-shadow {
        height: 70px;
    }
    .bottom_dashbox .id_box {
        max-height: 110px;
    }
    .patient_bottom .ref-scrollbox {
        height: auto !important;
    }
    .patient_bottom .ref-scrollbox>div {
        position: static !important;
        overflow: visible !important;
    }
    .patient_bottom .ref-scrollbox>div .id_box {
        max-height: inherit;
    }
    .patient_bottom .ref-scrollbox>div .id_box .list-item-content-Ref {
        height: inherit;
        min-height: 100px;
    }
    svg.HomeLink {
        margin-left: -3px;
        height: 20px;
        width: 20px;
    }
    .ref-scrollbox div {
        overflow: unset !important;
    }
    /* .ref-scrollbox.new_div {
           height: 250px!important;
       } */
    .HomeLink {
        margin-left: 0;
        vertical-align: middle;
    }
    .HomeLink path {
        fill: #000 !important;
    }
    .referral-right-actions button {
        font-size: 9px !important;
    }
    button.chat_btn1.btn1 {
        margin-right: 12px!important;
    }
    .pink_button {
        display: flex;
        position: absolute !important;
        left: 2% !important;
        top: 50% !important;
    }
    .pink_button button {
        font-size: 11px;
    }
    .referral-right-actions {
        margin-top: 8px;
    }
    .ProviderProfile {
        height: 470px;
        overflow: hidden;
        overflow-y: scroll;
    }
    .referral-right-box {
        height: 270px !important;
        overflow: hidden;
        overflow-y: scroll;
    }
    .login-content input {
        font-size: 16px !important;
    }
    .no-data-div {
        height: auto;
    }
    .ref-scrollbox-1 .referal_avail {
        margin: 30% auto;
    }
    div#decline-referral-title+form textarea {
        font-size: 16px !important;
    }
    .search-box+button {
        position: absolute;
        right: 0;
        top: 5px;
    }
    .Comment-TextField textarea {
        font-size: 16px !important;
    }
    .Ref-tags {
        position: absolute;
        right: 31px;
        top: 41%;
    }
    .info-text {
        text-align: left;
    }
    .search-box-cover {
        position: relative;
        height: 32px;
        margin-left: -7px;
    }
    .AllReferrals-page .search-box {
        width: auto;
        margin-left: 20px;
    }
    h2.titlename {
        margin-bottom: 10px;
    }
    .hospital {
        text-align: center;
        margin-top: 6px;
    }
    .AllReferrals-page .box-filter-ref .MuiInputLabel-shrink {
        font-size: 12px !important;
    }
    .id_box {
        padding: 0 !important;
        position: relative;
    }
    .AllReferrals-page .list-item-content-Ref,
    .list-item-content-notification {
        width: 99% !important;
    }
    .Ref-tags {
        margin-left: 20px;
    }
    .Ref-categorybox {
        width: 90px !important;
    }
    .category-value {
        padding: 0px !Important;
    }
    h3.ref-id {
        font-size: 13px !important;
    }
    .tag-date-right {
        font-size: 10px !important;
    }
    .info-text {
        font-size: 8px !important;
        width: 100%;
    }
    .add-modal.CommentModal .CommentModal-title {
        margin-top: 50px;
    }
    .AllReferrals-page .btn3 {
        line-height: 15px !important;
    }
    .CommentModal .btn1,
    .NotesModal .btn1 {
        line-height: 14px !important;
        font-size: 9px;
    }
    .AllRef-content button {
        margin-top: 10px;
        margin-right: -2px !important;
    }
    .Ref-Hospital-Patients {
        text-align: center;
        margin-top: 17px;
    }
    .CommentModal .ref-info,
    .notification-header-info .ref-info {
        font-size: 10px;
    }
    .ref-val {
        font-size: 8px !important;
    }
    .CommentModal-info>div {
        padding-right: 60px;
    }
    .Comment-TextField textarea {
        max-width: 100% !important;
        height: 100px;
    }
    .divcontainer-Ref {
        position: relative;
    }
    .show-text {
        position: absolute;
        top: 4px;
        right: 0;
    }
    .Ref-tags {
        position: absolute;
        right: 35px;
        top: 43%;
    }
    .AllReferrals-page .tag-date {
        font-size: 11px;
        margin-top: -1px;
    }
    .AllReferrals-page .list-item-content-Ref {
        padding: 8px 0px;
    }
    .AllRef-content {
        padding: 8px;
    }
    .action-box-ref {
        justify-content: space-between !important;
    }
    .btn1.down_face {
        position: absolute;
        left: 5px;
        top: 64%;
    }
    .AllReferrals-page .list-item-content-Ref,
    .list-item-content-notification {
        position: relative;
        height: 130px;
    }
    .no-shadow {
        position: relative;
    }
    .no-shadow .Ref-categorybox {
        position: absolute;
        right: 9px;
    }
    .commentTableScroll {
        overflow-x: scroll !important;
        overflow-y: scroll !important;
        max-height: 300px !important;
    }
    .search-box-cover input {
        margin-left: -20px;
    }
    .select-referral-text {
        display: block;
        margin: 22% auto !important;
    }
    .user-image svg {
        margin-left: 13px !important;
        margin-top: -4px !important;
    }
    .ref-scrollbox-1 {
        height: 280px !important;
    }
    .comments-section form>div>div {
        width: 100%;
    }
    .comment-title+form div {
        padding: 0;
    }
    .undefined.Sidebar-logoImage {
        position: relative;
        width: 100%;
    }
    .undefined.Sidebar-logoImage img {
        width: 200px;
        position: absolute;
        bottom: -11px;
        left: 47%;
    }
    .mobileMenuSidebarxxx ul a .fa.fa-circle {
        font-size: 10px;
        color: deeppink;
        margin-left: -9px;
    }
    .mobileMenuSidebarxxx ul a span {
        margin-right: 10px;
    }
    .mobileMenuSidebarxxx ul {
        display: grid;
    }
    .mobileMenuSidebarxxx ul a {
        padding: 12px 17px;
        font-size: 14px;
        color: #020202;
        font-weight: 500;
    }
    .Sidebar-logo a {
        border-bottom: 2px solid #0aaaa9;
        width: 100%;
        text-align: center;
    }
    .org-form-cover div {
        width: 100% !important;
    }
    .org-form-cover {
        display: contents !important;
    }
    .form-button-cover.form-actions {
        width: 100% !important;
    }
    .front-modal .form-button-cover.form-actions {
        display: block;
        margin-top: 200px;
    }
    .settop {
        margin-top: 0;
        width: 100% !important;
        position: absolute;
        left: 0px;
    }
    .front-modal {
        position: relative;
    }
    .ProviderProfile.PaterintCriteria .SubscriptionDetailsSection.front-modal div div {
        padding-right: 0px !important;
        font-size: 11px;
    }
    .all-cover .setcat-criteria.Infectious-cover .criteriaScroll {
        height: calc(100vh - 590px) !important;
    }
    .user-info-notification {
        width: 60%;
    }
    .comments-section form div {
        padding: 0;
    }
    .section-category-cover {
        margin-left: 20px;
    }
    .comments-section textarea {
        height: auto;
    }
    .hambujer_icon span svg {
        font-size: 30px;
    }
    .hambujer_icon span {
        color: #7b7a7a;
    }
}

span.edit-action {
    margin-right: 4px;
}

.large_square {
    vertical-align: top;
}

.add-modal.editproviderDialog.ManageProvideCategories .MuiDialog-paperWidthSm {
    max-width: 900px;
    width: 100%;
    padding: 10px 20px;
}

.service_cat {
    margin-top: 10px;
}

.manage_title {
    padding-left: 0 !important;
}

.nocategory {
    margin: 15px 0px;
    font-size: 14px;
}

.notes-div.false img {
    width: 26px;
}

.notes-div.change-right-position-notes img {
    width: 27px;
}

.notes-div.change-right-position img {
    width: 27px;
}

div#provider-dialog-title\~div {
    overflow: hidden;
}

.serviceScroll {
    max-height: 400px !important;
}

.pink_button {
    display: flex;
    position: absolute;
    left: 72%;
    top: 20%;
}

.AllRef-content {
    position: relative;
}

button.chat_btn1.btn1 {
    margin-right: 12px!important;
}

.facname {
    color: white;
    margin-top: 2px;
    margin-left: 2px;
}

.profile span {
    font-size: 13px!important;
}

.Dashboard-mainPanel-2.ProviderHeader {
    overflow: hidden;
}

.top_headimport .search-box-cover {
    display: flex;
    height: 30px;
    padding-left: 5px;
}

.import_filter div {
    height: 44px;
    width: 90px;
}

button.pointer.btn1.import_btn1 {
    font-size: 13px;
    width: 145px;
    height: 35px!important;
}

table.importpateint_table {
    width: 100%;
}

tr.green_row th {
    padding: 10px;
}

tr.green_row {
    background: #01A7A5 !important;
    color: white;
    font-size: 13px;
}

table.importpateint_table {
    font-size: 13px;
}

table.importpateint_table tbody tr td {
    padding: 0px 10px;
}

.imp_listing {
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 15px;
}

table.importpateint_table tr:nth-child(even) {
    background: #f7f3f3;
}

table.importpateint_table tr:nth-child(odd) {
    background: #01a7a629;
}

.owl-carousel.owl-loaded {
    display: initial !important;
}

.top_headimport .box-filter {
    width: 100px;
}

.patient-list-page .top_headimport .box-filter .MuiOutlinedInput-input {
    padding: 0px 14px 0 5px !important;
    line-height: 31px !important;
}

.top_headimport {
    margin-top: 10px;
}

.patientlist-scroll thead tr th {
    color: white !important;
    font-size: 13px!important;
    font-weight: 700 !important;
    min-width: auto !important;
    padding: 8px !important;
}

.patientlist-scroll tbody tr td {
    padding: 0px !important;
}

.patientlist-scroll {
    margin-top: 2px;
}

tr.green_row td {
    padding: 5px !important;
}

table.importpateint_table tr td:last-child {
    text-align: left!important;
}

table.importpateint_table tr td:nth-child(3) {
    width: 20%;
}

.MuiTablePagination-toolbar {
    min-height: 35px!important;
}
/* syed's css end */


/* Sunil Css Start */

.PatientList.scrollmanagment .YOnlytScrollbars.TabScroll {
   overflow: auto!important;
   -ms-overflow-style: none; /* for Internet Explorer, Edge */
   scrollbar-width: none;
}
.PatientList.scrollmanagment .YOnlytScrollbars.TabScroll::-webkit-scrollbar {
   display: none;
}

.chip-list-div .MuiChip-root.chipval + .PLangList.scrollx_hide {
   display: none;
}

.font-11 {
    font-size: 11px;
}

.list-item-content-data .font-11 span {
    font-size: 11px;
}

.send-emr-note {
    width: 100%;
}

.send-emr-note textarea {
    min-height: 50px;
}

.send-emr-note .form-control {
    margin-bottom: 15px;
}

.send-emr-note .form-control label {
    font-size: 12px;
    color: #000;
}

.send-emr-note .form-control h5 {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.category-value-status-list .owl-carousel {
    position: relative;
    display: inline-block !important;
}

.category-value-status-list .owl-carousel .owl-nav {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.category-value-status-list .owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: 10px;
}

.category-value-status-list .owl-carousel .owl-nav .owl-prev {
    left: 10px;
    position: absolute;
}

.patient-list-page.patient-list-page-new .owl-carousel .owl-item {
    min-width: 100px;
}

.note-pushed-text {
    display: block;
    margin-top: 5px;
}

.forminput-withlabel .formControlShadow {
    box-shadow: 2px 2px 4px #333333;
    padding: 4px 4px 2px 10px;
    margin-top: 10px;
    border-radius: 4px !important;
}

.forminput-withlabel {
    padding-top: 20px;
    width: 100%;
}

.forminput-withlabel label.MuiFormLabel-root {
    color: #000 !important;
}

.conoimputs {
    width: 100%;
    height: auto;
    float: left;
}

.profile input[disabled],
.profile input[readonly] {
    color: #000;
}

.top_headimport.adjustpadd {
    margin-left: -8px !important;
    margin-right: -8px !important;
    width: calc(100% + 16px) !important;
}

button.pointer.btn1.import_btn1.remove-from-btn {
    width: 170px;
}
/* .top_headimport.adjustpadd button.pointer.btn1.import_btn1 {
    line-height: 35px !important;
} */

.comments-section.match-height-table .commentTableScroll div {
    max-height: 200px !important;
}

.comments-section.PopComment form .commentTableScroll {
    overflow-y: auto!important;
    max-height: 200px!important;
}

.comments-section.PopComment form .commentTableScroll table {
    min-height: 120px;
}

.comments-section.PopComment form .commentTableScroll div {
    overflow: auto!important;
}

.PatientList .YOnlytScrollbars.TabScroll {
    position: relative !important;
    overflow: unset !important;
}

.notifications-details-section .comments-section .commentTableScroll div {
    max-height: 200px !important;
}

.right-box-content.categorylist-cover .box-header .patientName {
    cursor: pointer;
    font-family: 'SFProDisplay-Bold';
}

#facility-index.mb-20 .box-header .patientName {
    /* text-decoration: underline; */
    position: relative;
   display: inline-block;
}
#facility-index.mb-20 .box-header .patientName::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 2px;
   background: #1493ca;
}
.right-box-content.categorylist-cover .box-header .patientName.selectedtask-name {
    font-family: 'SFProDisplay-Medium';
    cursor: auto;
}

.send-emr-note-modal .MuiPaper-rounded {
    width: 100%;
    max-width: 320px;
}

.check-input.admitted-checkbox-auto .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
}

.min-height-threehundred {
    min-height: 300px;
}

.loader-width-position .MuiLinearProgress-indeterminate {
    width: 100%;
    position: absolute;
    top: 0;
}

.patient-notification-title {
    font-family: 'SFProDisplay-Regular';
    line-height: 1.3;
}

.patient-notification-user {
    font-family: 'SFProDisplay-Regular';
}

.patient-notification-user img {
    margin-right: 10px;
}

.date-dc.warning-sign svg {
    fill: #f00;
    margin-right: 0px;
    margin-left: 6px;
}

.DC.warning-sign svg {
    fill: #f00;
    margin-right: 0px;
    margin-left: 6px;
    max-width: 16px;
    max-height: 16px;
}

.DC.warning-sign {
    align-items: center;
    display: flex;
}

.comments-section.PopComment form .commentTableScroll div {
    margin-bottom: 0px !important;
}
.right-box-cover .middle-section-content.middle-section-content-middle.relativeContent {
   position: relative;
   transform: unset;
   left: 0;
   top: 0;
   margin-top: 0;
   margin-bottom: 10px;
}
/* .user-msg-tab div {
   border: solid 2px #f7aec9;
   border-radius: 8px;
   padding: 8px 15px;
   margin: 5px 0px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
} */
.user-msg-tab div {
   border: solid 2px #f7aec9;
   border-radius: 8px;
   padding: 3px 15px;
   margin: 5px 0px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   line-height: 16px;
}
.NotificationComents .DpComment {
   border: solid 2px #f7aec9;
   border-radius: 8px;
   padding: 8px 15px;
   margin: 5px 0px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   max-width: 50%;
}
.NotificationComents .ProviderComment {
   border: solid 2px #f7aec9;
   border-radius: 8px;
   padding: 8px 15px;
   margin: 5px 0px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   margin-left: 10px;
   max-width: 50%;
}
.TemplateFaq .template-right h3 {
   font-size: 26px;
   color: #393a3d;
   margin-bottom: 20px;
   font-family: 'Rounded Mplus 1c Medium' !important;
}

.PatientDetailCollapse {
   padding-left: 15px;
}
.multiple-selection.hideshhow .CollapseBtn{cursor: pointer;}

.multiple-selection.hideshhow .CollapseBtn span {
   display: flex;
   align-items: center;
   font-size: 14px;
   justify-content: center;
}
.pos-rel.FilterDiv {
   position: absolute;
   right: 16px;
   top: 400px;
   z-index: 999999;
}
.pos-rel.FilterDivAwait {
    position: absolute;
    right: 16px;
    top: 5px;
    z-index: 999999;
 }
.details-section.hideshowDetail + .ProviderSection .find-provider-tabs .YOnlytScrollbars.TabScroll{
   height: calc(-360px + 100vh)!important;
}
/* .ReferralTypeHeadingFlex {
   display: flex;
   align-content: center;
}
.ReferralTypeHeadingFlex h4.label {
   padding-top: 3px;
}
.ReferralTypeHeadingFlex .value {
   padding-left: 5px;
} */
.ReferralTypeHeadingFlex div {
   display: flex;
   align-items: center;
}
.ReferralTypeHeadingFlex div h4{
   padding-right: 5px;
}
.multiple-selection.hideshhow .CollapseBtn {
   cursor: pointer;
   background: #51c1c0;
   padding: 0px 5px;
   border-radius: 4px;
   min-width: 68px;
}
.details-section  .multiple-selection.hideshhow .fa-chevron-left {
   margin-right: 8px;
}
.NoMargin form .commentTableScroll div {
   margin-right: 0px !important;
}

.referral-right-content .referral-right-actions {
   margin-top: 15px;
   margin-bottom: 20px;
}
/* .PLangList.scrollx_hide div, .PatientList .YOnlytScrollbars div {
   overflow-x: hidden!important;
} */
.PLangList.scrollx_hide div::-webkit-scrollbar, .PatientList .YOnlytScrollbars div::-webkit-scrollbar {
   display: none!important;
}
.comment-TextField.scrollbar_hide textarea::-webkit-scrollbar{
   display: none;
}
.comment-TextField.scrollbar_hide textarea{
   -ms-overflow-style: none;  /* IE and Edge */
 scrollbar-width: none;  /* Firefox */
}

.chip-list-div .PLangList.scrollx_hide {
   min-height: 20px!important;
   overflow-y: auto!important;
}
.YOnlytScrollbars div {
   margin-right: 0px!important;
   /* margin-bottom: 0px!important; */
}
.sendReferralConfirmationModal .PatientList div {
   margin-bottom: 10px;
}

.commentTableScroll.HightAndBg {
   background: #fff;
   min-height: 100px !important;
}
.commentTableScroll.HightAndBg div {
   overflow: auto !important;
   margin: 0px !important; 
}
.comments-section.PopComment form .commentTableScroll.autoscroll div {
   max-height: unset !important;
}

.addBlockPopup .MuiDialog-paperScrollPaper button {
   min-width: 160px !important;
   border: none !important;
}
.addBlockPopup .MuiDialog-paperScrollPaper {
   max-width: 460px!important;
}
.addBlockPopup .MuiDialog-paperScrollPaper .addBlockContent {
   padding-bottom: 25px;
}
.box-category{
   width: 36% !important;
 }
 .box-Subscription{
   width: 38% !important;
 }
 .box-price{
   width: 94px !important;
 }
 .provider_type
{
 width: 130px;
 display: inline-flex;
 flex-wrap: wrap;
}
.gray_text
{
   color: gray;
   font-size: 12px;
}

@media (max-width: 850px){
   .details-section + .ProviderSection .find-provider-tabs .YOnlytScrollbars.TabScroll {
       height: calc(-647px + 100vh) !important;
   }
   .details-section.hideshowDetail + .ProviderSection .find-provider-tabs .YOnlytScrollbars.TabScroll{
       height: calc(100vh - 480px) !important;
   }
   .find-provider-tabs .user-info-tab {
       width: 100% !important;
   }
   .EditProviderModal{
       min-width: 430px;
       padding-right: 20px;
   }  
}
@media(max-width: 575px){
   #facility-index .referral-right-box {
       height: calc(100vh - 470px) !important;
   }
}
/* Sunil Css End */
.mile-text-box{
   margin-top: -55px !important;
}
.mile-left{margin-left: 17px !important;}
.mile-category{margin-top: -24px !important;}
.mile-text-category{
   margin-top: -20px !important;
}
.referral_text{font-size: 1.125rem;text-align: left !important;}
.red-text{color: red !important;font-size: 0.75rem;text-align: left;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: 400;
   line-height: 1.66;}


   .provider-col-1, .provider-col-2 {
       width: 50% !important;
       margin-right: 12px;
   }
   
   .provider-col-3 {
       width: 170px !important;
   }
   .provider-subscription-select{
       height: 34px;
   }
   .tab-heading-text {
       margin-bottom: 20px;
   }
   .map-svg{
       padding-right: 5px;
     }
     .patient_ref_text{font-size: 19px;}
   .address-text-bold
   {
       font-weight: bold;
       font-size: 15px;
   }
   .flex-wrap{
       flex-wrap: wrap;
   }
   .AllReferrals-page .box-filter-ref.fr-1.insurance-filter{
   margin-right: 238px;
   width: 242px !important;
   margin-top: 20px;}
   .end-box{
       justify-content: end;
   }

.OwlCarousel-padding > .owl-carousel
{
   padding: 0px !important;
}
.OwlCarousel-padding > .owl-carousel >.owl-stage-outer >.owl-stage > .owl-item
{
   padding: 0px !important;
   margin-right: 13px !important;
}
.profile input[readonly] {
   color: grey;
}
.infoicon-svg
{
   margin-top: 3px;
   cursor: pointer;
   position: absolute;
   z-index: 999;
}

     @media print {
       .app-calendar-date {
         display: block !important;
         visibility: visible !important;
       }
     }