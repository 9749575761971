button.pointer.btn1.import_btn {
    margin-left: 13px;
}
.top_headimport {
    display: flex;
}
.top_headimport .search-box-cover {
    display: flex;
    height: 40px;
    padding-left: 5px;
}
.oval_box .tags-cover {
    margin: 0px 45px;
}
.tag1 {
    background: none!important;
    color: black;
    font-size: 8px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-top: 10px;
    padding: 0px;
    text-align: center;
    width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.MuiDialog-paperWidthMd {
    width: 960px!important;
}
p.tag1.mr-10.status3.Planning {
    background: none!important;
    color: black;
    font-size: 8px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-top: 10px;
    padding: 0px;
    width: 90px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.patient-list-page .top_headimport .search-box-cover input {
    padding-left: 5px !important;
    width: 255px;
    border-radius: 4px !important;
}
.top_headimport {
    width: 100%!important;
    justify-content: space-between;
}
p.tag1.mr-10.status3.Planned {
    background: none!important;
    color: black!important;
    font-size: 9px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-top: 10px;
    padding: 4px;
}
p.tag1.mr-10.status3.Issue {
    background: none!important;
    color: black!important;
    font-size: 9px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-top: 10px;
    padding: 4px;
}
.patient-list-page.patient-list-page-new .list-item-content {
    border: 2px solid #FFF;
    padding-bottom: 15px !important;
    padding-top: 10px !important;
}
.list-scrollxx.category-value-status-list.pateint_box .owl-stage {
    width: 100%!important;
    display: flex;
}
.list-scrollxx.category-value-status-list.pateint_box .owl-item.active {
    margin: 0px 10px!important;
}
@media (width: 768px) {
p.tag1.mr-10.status3.Planning {
    margin-top: 0px;
    margin-left: 20px;
}
.tag1 {
    margin-top: 0px !important;
    margin-left: 20px!important;
}
p.tag1.mr-10.status3.Planned {
    margin-top: 0px !important;
    padding: 4px;
}
p.tag1.mr-10.status3.Issue {
    margin-top: 0px !important;
}
}
@media (max-width: 850px) {
    .patient-list-page.patient-list-page-new .list-item-content {
        height: auto !important;
    }
    .category-value-status-list .owl-carousel .owl-item {
        width: 112px !important;
    }
    div.patient-list-page .list-item-content > div > div {
        width: auto !important;
    }
    .oval_box div {
        float: left;
        display: block;
        margin: 0 auto;
        text-align: center;
        align-items: center;
    }
    .tag.task2 {
        margin-left: 20px;
    }
    p.tag1.mr-10.status3.Planned {
        margin-top: 0px !important;
        padding: 4px;
    }
    .tag1 {
        margin-left: 20px!important;
        margin-top: 0px !important;
    }
    
}
