.google-auto-complete{
    width: 100%;
}
.google-auto-complete ul{
    position: absolute;
            background: white;
            z-index: 10;
            width: calc(100% - 48px);
            list-style: none
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    overflow-y: initial !important;
}
.div-fourty{
    width: 40% !important;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
}
.div-sixty{
    width: 60% !important;
    display: inline-block;
    margin-bottom: 10px;
}
.w-100{
    width: 100%;
}
.d-new-flex{
    display: flex;
}
.text-black{
    color: #000000;

}
.text-black:hover {
    color: #000000;

}


/* naveen */

.mr-10{
    margin-right: 10px;
    
}
.new-space{
    display: inline-block;
    min-width: 145px;
    margin-top: 5px;
}
.pl-8{
    padding-left: 8px;
}
.box-width {
    display: flex;
    margin-bottom: 20px;
}
.box-width-left
{
    width: 254px;
    padding-right: 16px;
}
.box-width-left .MuiInput-root{
    width: 100%;
}
.btn-new-change .MuiButton-label{
    border-radius: 5px;
    color: #fff !important;
}
.mt-0{
    margin-top: 0;
}
.new-space2{
    display: inline-block;
    min-width: 70px;
    margin-top: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.new-font{
    font-size: 18px !important;
    color: #000000 !important;
    font-weight: bold;
}
.text-wrap{
    white-space: nowrap;
}