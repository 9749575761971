.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dim background */
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center;     /* Vertically center the content */
    z-index: 1002;
    pointer-events: auto;    /* Ensure the overlay blocks interaction with background content */
}

.popup-content {
    background-color: #01A7A5;
    padding: 33px;
    border-radius: 10px;
    width: 70%; /* Adjust the width as needed */
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1002;           /* Ensure the popup content is above the overlay */
    pointer-events: auto;    /* Allow interaction with popup */
    margin-top: -150px;      /* Move the popup upwards (adjust this value as needed) */
}

body.popup-open {
    overflow: hidden; /* Prevent scrolling of background when the popup is open */
    z-index: 1002;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5em; /* Increase the size */
    color: #333; /* Change the color as needed */
    cursor: pointer; /* Change cursor to pointer */
    position: absolute; /* Position it at the top right */
    top: 15px; /* Distance from the top */
    right: 15px; /* Distance from the right */
}

.close-button:hover {
    color: red; /* Change color on hover */
}

.patient-criteria {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
}
