/*!

 =========================================================
 * Material Dashboard React - v1.7.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */



 *{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #F5F5F5;
  color: #333333;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}
.custom-table .MuiTable-root{
  user-select:none;
  -webkit-user-select:none;
}
/*03-12-2019*/
.drop-btn{
  float: left !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  min-width: 30px !important;
  margin-top: 10px !important;
}
.drop-btn span img{width: 13px;}
.menuArrowMain {margin-right: 15px;position: relative;background-color: #01A7A6 ;}/*border-top: 1px solid #000; border-bottom: 1px solid #000;} */
button.buttonlinkHeader{text-transform:none !important;font-size:18px !important;}
button.buttonlinkHeader span{justify-content: left;line-height: 17px;}
.count{width: 20px;height: 20px !important;line-height: 20px !important;}
ul .MuiListItem-root.Mui-selected,ul .MuiListItem-root.Mui-selected:hover{background-color:#01A7A6;color:#FFF;}
ul .MuiListItem-button:hover{color:#FFF;box-shadow:0 4px 20px 0 rgba(0,0,0,.14),0 7px 10px -5px rgba(0,0,0,0.4);background-color:#757575;}
.tblDownloadBtn{float:right;color:#01A7A6;background-color:#FFF;width:28px;height:28px;line-height:30px;text-align:center;border-radius:100%;font-size:14px;box-shadow:0px 0px 5px rgba(0,0,0,0.2);}
.tblDownloadBtn:hover{background-color:#333;color:#FFF;}
div.Sidebar-logoImage{width:auto;max-height:inherit;}
a.Sidebar-logoLink{font-size:0;display:inline-block;}
div.Sidebar-logoImage img{position:static;max-width:100%;height:auto;width:100%;}
div.Sidebar-logo{background-color:#FFF;border: 8px solid #fff; padding: 0 0;}
.custom-table th.MuiTableCell-root span.shortingMain{position:relative;display:inline-block;vertical-align:middle;width:20px;height:18px;font-size:16px;}
.custom-table th.MuiTableCell-root span.shortingMain i{position:absolute;left:2px;top:0px;cursor: pointer;padding: 0 1px;}
.custom-table th.MuiTableCell-root span.shortingMain i.active {color: #667f2a;}
.custom-table th.MuiTableCell-root span.shortingMain i.fa-long-arrow-down {right: 0;left: inherit}
.custom-table td.MuiTableCell-root .icon-cover {margin-right: 15px !important}
.custom-table td.MuiTableCell-root .icon-cover:last-child {margin-right: 0px !important;}
.profile-content .MuiFormGroup-root.fullwidth{width:100%;}
div.gray-bg-color{background-color: #F5F5F5;}
div.warning-header{;padding:10px 0 0; width:100%;}
div.warning-header i{vertical-align:middle;margin:0 0 2px 0;font-size:150%;}
.addBlockbtn{display:inline-block;width:100%;text-align:center;margin:10px 0;}
.addBlockDesc{display:inline-block;width:100%;text-align:center;max-width:380px;margin:0 auto;color:#333;line-height:1.5;margin-bottom:10px;}
.addBlockContent{display:inline-block;width:100%;text-align:center;}
.addBlockbtn .refreshBtn{background-color:#ef5350;color:#FFF;padding:8px 16px;font-size:16px;}
.addBlockbtn .refreshBtn span{color:#FFF !important;}
.addBlockbtn .refreshBtn:hover {background-color: #de4340;color: #FFF;}
.loginPageLink {width: auto;float: right;}
.loginLogoInner{display:inline-block;padding:10px 15px 0;border-radius:5px;}
div.loginLogoMain{display:inline-block;width:100%;text-align:center;}
.loginLogoInner img {
	max-width: 293px;
	margin-bottom: 10px;
}
.selectedAdnetwork{font-size:14px;display:inline-block;padding:8px 10px;font-weight:500;border-left:3px solid #01A7A6;background-color:#efebeb;margin-top:15px;}
.sidebar-cover .sidebarLeftNavLink {
  color: rgba(0, 0, 0, 0.87);
}
.sidebar-cover  .Sidebar-sidebarWrapper {
  background-color: #fff;
}
.sidebar-cover .active .sidebarLeftNavLink,
.sidebar-cover a:hover,
.sidebar-cover a:hover .sidebarLeftNavLink,
.sidebar-cover a:hover svg {
  color: #fff;
}
button.buttonlinkHeader span.name-text{
  color: #fff !important;
}
button.buttonlinkHeader span.count{
  display: none;
}
/*Theme CSS*/
/* div.themeBgChange:after {background: #fff}
div.cusSelected {background: #01A7A6 !important;color: #FFF;}
div.cusSelected .sidebarLeftNavLink {color: #FFF}
div.Sidebar-logo{border: 0;background-color: transparent;}
body .sidebarLeftNavLink {color: #333}
ul .MuiListItem-button:hover {background-color: #333333;}
.Sidebar-sidebarWrapper ul .MuiListItem-button:hover {background-color: #e8e8e8;}
body .cusCardTitle{ background:#01A7A6  !important;}
body .table-header th{color: #01A7A6;}
body ul .MuiListItem-root.Mui-selected, body ul .MuiListItem-root.Mui-selected:hover,body .form-button-cover button, body .form-button-cover button:hover{background-color: #01A7A6;}
body .form-button-cover button[disabled], body .form-button-cover button[disabled]:hover {background-color: #01A7A6;} */
div.themeBgChange:after {background: #fff}

.d-flex.justify-content-between {
  display: flex;
  justify-content: space-between;
}
.d-flex.justify-space-evenly {
	display: flex;
	justify-content: space-evenly;
}
.loginPageLink.forgot-link {
	margin: auto 0;
}


.MuiInput-underline::before {
	border-bottom: 1px solid #949494 !important;
}
.MuiInput-underline::after {
  transform: none !important;
  border-bottom: 1px solid #949494 !important;
}
.addBlockPopup .MuiDialog-paperScrollPaper {
	max-width: 412px;
}
.possword-input {
  position: relative;
}
.svg-icon {
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
}
.btn1 {
	background-color: #F07DA8 !important;
	padding: 0 5px !important;
	margin-right: 0px !important;
	border-radius: 40px !important;
	box-shadow: 2px 2px 4px rgb(51, 51, 51) !important;
	line-height: 48px !important;
  height: 48px !important;
  min-width: 100px !important;
  border: none !important;
  color: #fff !important;
}

.btn2 {
	background-color: #999 !important;
	padding: 0 5px !important;
	margin-right: 0px !important;
	border-radius: 40px !important;
	box-shadow: 2px 2px 4px rgb(51, 51, 51) !important;
	line-height: 48px !important;
  height: 48px !important;
  min-width: 100px !important;
  border: none !important;
  color: #fff !important;
}

.btn3 {
	background-color: #01A7A6 !important;
	padding: 0 5px !important;
	margin-right: 0px !important;
	border-radius: 40px !important;
	box-shadow: 2px 2px 4px rgb(51, 51, 51) !important;
	line-height: 48px !important;
  height: 48px !important;
  min-width: 100px !important;
  border: none !important;
  color: #fff !important;
}

.Sidebar-logo::after {
  height: 0 !important;
}
.h-title {
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  color: #000 !important;
}
.h-title b {
	font-weight: 500 !important;
}
.button-cover .btn1 {
  font-size: 14px !important;
  padding: 0 20px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 32px !important;
  line-height: 32px !important;
  border-radius: 30px !important;
}
.cusCardTitle h4 {
  font-weight: 400 !important;
  color: #fff !important;
  font-size: 1rem !important;
}
.custom-container {
  padding-bottom: 30px !important;
}
.modal-actions .btn1 .MuiButton-label,
.form-actions .btn2 a {
  color: #fff !important;
}
.modal-actions button:last-child {
  margin-left: 8px;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
	color: #2680EB;
}
.note-text {
  font-size: 13px;
  color: #000000;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.modal-actions .btn1,
.register-type .btn1,
.form-actions .btn1 {
  font-size: 15px !important;
  padding: 0 20px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 36px !important;
  line-height: 36px !important;
  color: #fff !important;
}
.modal-actions .btn2,
.form-actions .btn2 {
  font-size: 15px !important;
  padding: 0 20px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 36px !important;
  line-height: 36px !important;
  color: #fff !important;
}
.form-actions .btn2 {
  font-size: 15px !important;
  padding: 0 20px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 36px !important;
  line-height: 36px !important;
  color: #fff !important;

  margin-left: 20px;
}
.card-btn.btn1,
.card-btn.btn2,
.card-btn.btn3 {
  font-size: 15px !important;
  padding: 0 20px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 30px !important;
  line-height: 30px !important;
  color: #fff !important;
}
.modal-actions .MuiButton-textPrimary {
  border-radius: 40px;
  padding: 0px 20px;
  line-height: 36px;
}
.closeButton,
.add-modal-title button {
  top: 8px !important;
  color: #000 !important;
  right: 8px !important;
  position: absolute !important;
}
.btn1 .MuiButton-label,
.btn2 .MuiButton-label,
.btn3 .MuiButton-label {
  color: #fff !important;
}

.modal-actions {
  justify-content: flex-start !important;
  padding: 8px 24px !important;
  margin: 10px 0 20px !important;
}
.add-modal-title {
  border-bottom: 1px solid #9E9E9E;
  position: relative;
}
.add-modal-title h6 {
  text-transform: capitalize;
}
.add-modal .MuiDialog-paperWidthSm {
	max-width: 630px;
	width: 100%;
}
.add-modal .MuiPaper-rounded {
	border-radius: 10px;
}
.add-modal .MuiFormControl-root,
.add-modal .MuiInput-root {
  width: 100%;
}
.ID-label {
  font-size: 13px;
  font-weight: 400;
}
.ID-value {
  font-size: 16px;
  font-weight: 400;
}

.MuiTable-root th,
.MuiTable-root th svg path {
  color: #2680EB !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}
.MuiTable-root th svg {
  margin-left: 10px !important;
}
.MuiTable-root th:last-child,
.MuiTable-root td:last-child {
  text-align: right;
  width: 100px;
  min-width: 100px !important;
}
.MuiTable-root th:nth-last-child(2),
.MuiTable-root td:nth-last-child(2) {
  min-width: 100px !important;
}
.MuiTable-root th:last-child > span {
  display: none;
}
.MuiTableCell-root {
  padding: 14px 12px 14px 12px !important;
}
.MuiTable-root th {
  min-width: 150px !important;
}
.custom-container > div.MuiGrid-root,
.tableResponsive {
  max-width: 100%;
  overflow-x: auto;
}
.edit-action {
  margin-right: 10px;
  font-size: 15px;
}
.edit-action .fa {
  font-size: 15px;
  color: #9B63F8;
  cursor: pointer;
}
.delete-action .fa {
  font-size: 15px;
  color: #F24E4E;
  cursor: pointer;
}
.footer-actions .fa {
  font-size: 20px;
  line-height: 32px;
  height: auto;
  width: auto;
}
.ProviderHeader {
  font-family: 'SFProDisplay-Regular' !important;
}
.ProviderHeader div.Sidebar-logoImage img {
  max-width: 180px;
}
.ProviderHeader .custom-title {
  display: none;
}
.ProviderHeader .flex-box {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.ProviderHeader .w-100 {
  width: 100% !important;
  display: unset !important;
}
/* .admin-h {
  
  display: unset !important;
} */
.ProviderHeader .referrals-toggle .link1 {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 110px;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
  
}
.ProviderHeader .referrals-toggle .link2 {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 110px;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
}
.ProviderHeader .referrals-toggle .active-button {
  background-color: #01A7A6;
  color: #FFFFFF;
}
.ProviderHeader .referrals-toggle .inactive-button {
  background-color: #F0EDF4;
  color: #666666;
}
.ProviderHeader .flex-box .MuiBox-root {
  margin: auto 0;
}
.ProviderHeader .flex-box .fa.fa-caret-down {
  font-size: 14px;
  color: #F8F8F8;
  margin-left: 5px;
}
.ProviderHeader .flex-box .fa.fa-circle {
  font-size: 6px;
  height: 6px;
  width: 6px;
  color: #FF0000;
  position: absolute;
  right: 0px;
  top: 2px;
}
.ProviderHeader .right-tabs a {
  font-size: 14px;
  color: #FFFFFF;
}
.ProviderHeader .tab-link-1 a {
  position: relative;
}
.ProviderHeader header {
  background: rgb(240,237,244) ;
  background: linear-gradient(90deg, rgba(240,237,244,1) 0%, rgba(240,237,244,1) 24%, rgba(119,202,205,1) 57%, rgba(1,167,167,1) 100%);
  position: fixed;
}



.profile-content form {
  max-width: 1000px;
}
.change-password-dialogue {
  width: 100% !important;
}

.MuiSwitch-root {
  overflow: inherit !important;
}

.text-right {
  text-align: right !important;
}
.not-found-text {
 text-align: left;
 width: 100%;
 margin: 2rem 0;
}

.guidelines-data {
  text-align: left;
  margin-bottom: 2rem;
}
.guidelines-data b {
  font-weight: 600;
  font-size: 13px;
}
.guidelines-data p {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  margin: 8px 0;
}
.resetbtns button {
  padding: 0 20px !important;
}

.hide {
  display: none !important;
}
.add-modal .view-label {
  color: #333 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin-bottom: 4px !important;
}
.add-modal .view-label.value {
  color: #333 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.btn1:hover,
.btn1:focus,
.login-outer-cover button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover,
.login-outer-cover button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:focus {
  background-color: #01A7A6 !important;
}
.btn2:hover,
.btn2:focus {
  background-color: #333 !important;
}
.btn3:hover,
.btn3:focus {
  background-color: #F07DA8 !important;
}


@media only screen and (max-width: 959.95px) {
  
.mobileMenuSidebar{display:inline-block;width:100%;padding:15px 15px 0;margin-top:15px;border-top:1px solid #272525;}
.mobileMenuSidebar .menuArrowMain{position:relative;padding:0;margin-right: 0;}
.mobileMenuSidebar.mobileMenuSidebarTop {border-bottom: 1px solid #272525;padding-bottom: 10px;margin-top: 0;}
.mobileMenuSidebar .menuArrowMain .buttonlinkHeader{padding:0px 25px 0px 40px;width:100% !important;text-align:left;display:inline-block;}
.mobileMenuSidebar .MuiButton-label{position:relative;color:#333 !important;font-size:16px;white-space:normal;word-break:break-all;}
.mobileMenuSidebar .MuiButton-label span.AdnetworkSelect-notifications{width:20px;height:20px;line-height:20px;background-color:#f44336;border-radius:50%;text-align:center;color:#fff;margin:0;display:inline-block;vertical-align:middle;position:absolute;top:0;right:-25px;}
/* .mobileMenuSidebar .MuiButton-label div.PrivateHiddenCss{display:none;} */
.mobileMenuSidebar .menuArrowMain button.RegularButton-round{position:absolute;top:2px;margin:0;width:30px;height:30px;min-width:30px;}
.mobileMenuSidebar .profile-box{margin-top:0;padding-left: 0;}
.mobileMenuSidebar .RegularButton-round img {max-height: 8px;}
.mobileMenuSidebar .menuSelectList{display:none;}
.mobileMenuSidebar div ul li{margin:0 !important;}
.mobileMenuSidebar .profile-box a{color:#FFF !important;width: calc(100% - 50px) !important;}
.mobileMenuSidebar .profile-box a:nth-child(2){color:#01A7A6 !important;}
.mobileMenuSidebar .profile-box a button{margin:0;padding:0 !important;}
.MuiAvatar-root{width:42px !important;height:41px !important;border:1px solid #FFF;}
div.Sidebar-sidebarWrapper {height: calc(100vh - 10px);}
.mobileMenuSidebar.mobileMenuSidebarTop{border-bottom:1px solid #272525;padding-bottom:10px;}
.mobileMenuSidebarTop div.AdnetworkSelect-popperNav > div ul li{margin:0 !important;}
.mobileMenuSidebarTop div.AdnetworkSelect-popperNav > div ul{background-color:rgba(255,255,255,0.10);border-radius:0 0 5px 5px;padding-top:0;margin-top:5px;border-top:3px solid #656565;}
.addBlockPopup .MuiDialog-paperScrollPaper {margin: 15px}
div.warning-header{font-size:16px;}
div.addBlockContent{padding:0 15px;font-size:14px;}
.addBlockbtn{margin-top:0;}
.addBlockbtn .refreshBtn{font-size:14px;padding:7px 15px;}
.mobileMenuSidebarTop .menuArrowMain ul li {color: #333 !important; }
.mobileMenuSidebarTop .menuArrowMain ul .MuiListItem-root.Mui-selected, .mobileMenuSidebarTop .menuArrowMain ul .MuiListItem-root.Mui-selected:hover {
  background-color: #01A7A6;
  color: #FFF !important;
}
.mobileMenuSidebar .MuiButton-label .name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 19ch;
  display: block;
}

}


@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 293px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  /* .badge-primary-background-color: #9c27b0; */
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}
.MuiInput-formControl input{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cus_sidebar{
  background: #303030 !important;
}
.MuiButton-label>.MuiSvgIcon-root{
  color: #666666 !important;
}
.MuiButton-label{
  color: black !important;
}
.MuiFormLabel-root {
  color: #999999 !important;
  font-size: 14px !important;
}
.cusSelected{
  box-shadow: none !important;
  background: #01A7A6 !important;
}
.cusSelectedBtn{
  box-shadow: none !important;
  background: #c1c1c1 !important;
  padding: 0 !important;
}
.cusCardTitle{
  box-shadow: none !important;
  background: linear-gradient(60deg, #01A7A6, #01A7A6) !important;
}
.CardHeader-successCardHeader-355:not(.CardHeader-cardHeaderIcon-353){
  box-shadow: none !important;
  background: linear-gradient(60deg, #01A7A6, #01A7A6) !important;
}
.CardHeader-successCardHeader-250:not(.CardHeader-cardHeaderIcon-248){
  box-shadow: none !important;
  background: linear-gradient(60deg, #01A7A6, #01A7A6) !important;
}
.MuiButton-containedPrimary{
  background: #01A7A6 !important;
}
.MuiButton-outlinedPrimary{
  color: #01A7A6 !important;
  border: 1px solid #01A7A6 !important;
}
.MuiButton-outlinedPrimary:hover{
  border: 1px solid #3f51b5;
  background-color: #01A7A6;
}
.CustomInput-underline-164:after{
  border-color: #01A7A6 !important;
}
.CustomTable-tableHeadCell-371, .CustomTable-tableHeadCell-371.CustomTable-tableCell-372{
  font-size: 14px;
  font-weight: bold;
  color: #1976d2 !important;
}
.CustomTable-tableCell-372{
  font-size: 14px !important;
  color: #333333 !important;
}
.CustomTable-tableCell-372 img{
  margin-right: 15px;
}
.MuiInput-underline:before{
  border-bottom: 1px solid #d2d2d2 !important;
}
.CustomInput-formControl-163{
  margin: 0px 0 0 0 !important;
}
.MuiFormControl-root.undefined.CustomInput-formControl-163.MuiFormControl-fullWidth{
  margin: 0px 0 0 0 !important;
}
.adnetwork-content{
  padding : 0 10px 90px;
}
.form-button-cover{
  margin : 20px 0;
}
.form-button-cover button,.form-button-cover button:hover{
  background-color: #01A7A6;
  margin-right: 10px;
  padding: 5px 17px;
  font-weight: normal;
  font-size: 15px;
}
.cancel-back-btn, .back-btn span {
  color: #999999 !important;
}
.form-button-cover button span{
  color: #fff !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary .MuiButton-label{
  color: #999999 ;
}
.CardHeader-primaryCardHeader-253:not(.CardHeader-cardHeaderIcon-248){
  /* background: linear-gradient(60deg, #ab47bc, #8e24aa); */
  background: #01A7A6 !important;
  box-shadow: none !important;
}
.CardHeader-successCardHeader-360:not(.CardHeader-cardHeaderIcon-358){
  background: #01A7A6 !important;
}
/* CUSTOM CSS */
.campaign-header{
  margin: 2px 0;
}
.table-header th{
  font-size: 14px;
  color : #1976d2;
}
.custom-table-cover{
  box-shadow: none !important;
}
.custom-table .MuiTableCell-root{
  padding: 12px 8px;
  vertical-align: top;
}
.custom-table td.MuiTableCell-root{
  vertical-align: middle !important;
}
.campaign-btn-outer-cover{
  float : right;
}
.campaign-btn-cover{
  width: auto;
  margin-bottom: 40px;
  float: right;
}
.campaign-btn{
  float: right;
  background-color: transparent;
  border: 1px solid #303030;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.user-image{}
.pointer{
  cursor: pointer;
}
.MuiButtonBase-root.MuiButton-root.back-btn.MuiButton-text.MuiButton-textSecondary{
  background-color: #fff !important;
}
.MuiButtonBase-root.MuiButton-root.back-btn.MuiButton-text.MuiButton-textSecondary span{
  color: #999999 !important;
}
.material-select label{
  color: #999999 !important;
  font-size: 14px;
}
.MuiInput-formControl input,.MuiInput-formControl select{
  font-weight: normal !important;
}
.image-ref{
  margin-top: 25px;
}
.image-ref-img img{
  margin-top: 15px;
  margin-right: 10px;
  width: 60px;
  height:60px;
  border-radius: 50%;
  display: inline-block;
}
.image-ref-img span {
  font-size: 14px;
  font-weight: normal;
  display: inline-block;
  height: auto;
  vertical-align: middle;
  position: relative;
  top: -25px;
}
.image-ref-img span a{
  color: #01A7A6;
  font-weight: normal;
  cursor: pointer;
}
.change-pass-text{
  color: #303030;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}
.icon-cover{
  margin-right: 5px;
}
.campaign-status-img {
  vertical-align: top;
}

.campaign-status {
  min-width: 19px !important;
  padding: 0px !important;
}
.campaign-status:hover {  
  background-color:transparent !important;
}
.cusForm.MuiFormGroup-root{
  margin-bottom: 0 !important;
}
.cusForm .MuiFormControl-root{
  margin: 0 !important;
}
.createcampaign-content{
  padding: 0 10px 15px;
}
.createcampaign .MuiFormGroup-root{
  margin-bottom: 8px !important;
}
.createcampaign .MuiFormControl-root{
  width: 100%;
}
.profile-content .MuiFormGroup-root{
  margin-bottom: 0 !important;
  flex-direction: inherit;
}
.profile .MuiFormControl-root{
  margin: 0 !important;
  padding-bottom: 0;
  width: 100%;
}
.profile .MuiFormControl-root.material-select{
  margin-bottom: 10px !important;
  width: 100%;
}
.profile{
  padding: 0 10px;
}
.profile-content .error-class{
  padding-bottom: 10px;
  padding-top: 3px !important;
}
.cusForm .MuiFormControl-root{
  padding-bottom: 0 !important;
}
.custom-table{
  overflow-x: auto;
}
.custom-table .MuiTableCell-root{
  white-space: nowrap;
}
.login-forgot button.back-btn{
  padding: 0px 0px !important;
  min-width: 80px !important;
}
.login-forgot button.back-btn span{
  line-height: 33px;
}
.dash-tiles p{
  color: #666666 !important;
}
.sidebar-cover a:hover{
  text-decoration:none !important;
}
.login-content .MuiSelect-selectMenu{
  opacity: 0.8 !important;
}
.MuiSelect-select:focus{
  background-color: transparent !important;
}

.disabled-menu{
  opacity: 1 !important;
}
.selected-sorting-dropdown img.pop-check {
  display: none
}
/* to show right aligned item with image */
.sorting-items.select-sort{
  padding-left:16px
}
/* .sorting-items,
.capmpaign-items {
  padding-left: 40px !important
} */
/* ADVERTISEMENT PAGE CSS*/
.Card-card-395.advert-cover{
  flex-flow: wrap;
}
.dash-tiles.advertisement{
  box-shadow: 0 2px 6px 2px rgba(203, 203, 203, 0.9);
}
.dash-tiles.advertisement p{
  font-size: 16px !important;
  text-align: left !important;
  color: #333333 !important;
  font-weight: 500 !important;
  margin-top: 17px !important;
  padding: 0 20px !important;
  line-height: 27px !important;
  background-color : #f6f6f6;
  position: relative;
}
.cusCardheader{
  margin: -20px 0 0 !important;
}
.dash-tiles.advertisement p:before{
  content: '';
  height: 100%;
  border-right: 3px solid #01A7A6;
  position: absolute;
  left: 0;
}
.mt-15{
  margin-top: 15px !important;
}
.mt-0{
  margin-top: 0px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.dash-tiles.advertisement .advert-cardheader{
  margin:0 !important;
}
.advert-details{
  text-align: left;
  padding: 0 20px;
}
.assets-count,.vendor-count,.claims-count{
  display: inline-block;
  margin-right: 20px;
}
.assets-count img{
  margin-right: 4px;
}
.vendor-count img{
  vertical-align: text-top;
}
.grey-text{
  font-size: 14px;
  color: #999999;
  text-transform: capitalize;
  margin: 0 8px 0 10px;
  font-weight: 400;
}
.grey-text:after{
  content: ':';
  position: absolute;
  color: #999999;
  font-size: 14px;
}
.black-text{
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  font-family: "Roboto" !important;
}
.advert-details {
  padding-top: 6px;
}
.card-btn-cover{
  padding:7px;
}
.card-btn-cover button{
  float: right;
  border-radius:4px;
  padding: 3px 10px;
  border: none;
  color: #fff;
  font-size: 13px;
}
.card-btn-cover button.active{
  background-color: #01A7A6;
}
.card-btn-cover button.inactive{
  background-color: #f24747;
}
.card-btn-cover button.suspended{
  background-color: #bbbbbb;
}
.card-btn-cover button.expired_early {
  background-color: #deac33;
}

.form-button-cover button[disabled],
.form-button-cover button[disabled]:hover {
  background-color: #01A7A6;
  margin-right: 10px;
  padding: 5px 17px;
  font-weight: normal;
  font-size: 15px;
}
.dash-tiles.advertisement{
  margin-bottom: 5px;
  margin-top: 20px;
}
.custom-hr{
  float: left;
  width: 100%;
  border-top: 1px solid #ededed;
  border-bottom: none;
}
.form-cover{
  float: left;
  width: 15%;
  padding-top: 15px;
  padding-left: 15px;
}
.form-cover  label{
  font-size: 14px;
  color: #999999;
}
.form-cover .MuiSelect-selectMenu{
  font-size: 14px !important;
}
.form-cover em{
  font-style: normal;
}
.button-cover{
  float: right;
  padding: 25px 0;
  margin-right: 10px;
}

/* .button-cover button{
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  background-color: #01A7A6;
  border: none;
  padding: 10px 8px;
} */
.sort-by-cover{
  float: right;
  width: 72px;
  margin-top: 10px;
  margin-right: 20px;
}
.sort-by-cover label{
  color: #333333 !important;
  font-size: 14px;
  font-weight: 500;
}
.sort-by-cover .MuiFormGroup-root{
  margin-bottom: 0;
}
.sort-by-cover .MuiSelect-selectMenu{
  font-size: 14px;
}
.sort-by-cover label + .MuiInput-formControl{
  /* margin-top: 0 !important; */
}
.sort-by-cover .MuiInput-underline:hover:not(.Mui-disabled):before{
  border-bottom: none !important;
}
.sort-by-cover .MuiInputLabel-shrink{
  /* transform: none !important; */
}
.sort-by-cover .MuiInputLabel-animated{
  /* transition: none !important; */
}
.sort-by-cover .MuiSelect-icon{
  color: #000;
  top :calc(50% - 13px);
}
.sort-by-cover .MuiInput-underline:before{
  border-bottom: none !important;
}
.sort-by-cover .MuiInput-underline:after{
  border-bottom: none !important;
}
.dropdown-menu .MuiPaper-root{
  left: unset !important;
  right: 16px;
  top: 321px !important;
  max-width: fit-content !important;
}
.status-dropdown-menu .MuiPaper-root{
  /* left: unset !important;
  right: 106px; */
  /* top: 315px !important; */
  max-width: fit-content !important;  
  width: 100%;
}
.advert-count{
  color: #333333;
  text-transform: capitalize;
  padding: 25px 15px 0;
  font-weight: 400;
}

/* Assets Page CSS */
.upload-cover{
  float: left;
  width: 100%;
}
.upload-box{
  padding: 40px 0;
  text-align: center;
  background-color: #f3f3f3;
  border: 1px dashed #b7b7b7;
  border-radius: 8px;
}
.upload-box-active {
  box-shadow: 0 2px 9px 2px rgba(0, 0, 0, 0.3)
}
.drag-text{
  font-size: 16px;
  color: #c9c9c9;
  font-weight: 500;
  margin-bottom: 12px;                                                                                                                                                                                                                                      
}
.green-upload-btn{
  background-color: #01A7A6 !important;
  font-size: 15px !important;
  border-radius: 4px !important;
  border: none !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  padding: 6px 15px !important;
  line-height: 1.4 !important;
}
.green-upload-btn span{
  color: #fff !important;
}
.display-none{
  display: none !important
}
.visible-none{
  visibility: hidden !important
}
/* Add Advertisement CSS */
.add-advert-form{
  width: 25%;
  float: left;
}
.add-advert-form:first-child{
  margin-right: 30px;
}
.add-advert-form50{
  width: calc(50% + 30px);
  float: left;
}
.claim-text{
  color: #333333;
  font-weight: 500;
}
.add-claim{
  float: right;
  border: none;
  background-color: #fff;
  color: #01A7A6;
  font-size: 15px;
  border: 1px solid #01A7A6;
  border-radius: 4px;
  padding: 3px 10px 5.5px;
  text-transform: capitalize;
  cursor: pointer;
  line-height: 23px;
}
.add-claim span{
  font-size: 22px;
  position: relative;
  top: 2px;
  margin-right: 3px;  
}
/* Advertisement Details Page */
.advertdetails-cover .MuiGrid-grid-md-8{
  border-right: 1px solid #cccccc;
}
.select-dropdown-network-data{
  height: 32px;
}
.required-label::after{
  content: " *";
  color:#ea3c3c;
}
.advertdetails-cover .MuiGrid-grid-md-4{
  padding-left: 0 !important;
  height: auto
}
.advertdetail-small-cover{
  height: 100%;
}
.advertdetail-tab{
  width: 100% !important;
  height: 100% !important;
}
.advertdetail-tab button{
  font-family: "Roboto";
}
.tab-grey-border{
  float: left;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  position: relative;
  top: -3px;
}
.advertactive-tab{
  z-index: 9;
}
.active-tab {
  position: relative;
}
.adverttabs-header .active-tab:before {
  content: "";
  position: absolute;
  bottom: 0px;height: 5px;
  background-color: rgb(155, 190, 75);
  width: 80%;
  left: 10%;
  z-index: 10;
}
.adverttabs-header{
  height: 55px !important;
}
.adverttabs-header button{
  font-size: 16px;
  font-weight: 600;
}
.adverttabs-header + div{
  border-top: 1px solid #cccccc;
  top: -3px;
  overflow-y:  initial !important;
  height: calc(100% - 100px)!important
}
.adverttabs-header + div > div {
  height: auto !important;
}
.advertdetail-large-cover{
  padding: 0 30px;
}
.campaign-id{
  float: left;
  width: 100%;
}
.campaign-id h5{
  font-size: 16px;
  color: #999999;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px !important;
  padding: 25px 0 0;
  margin-bottom: 2px;
  float: left ;
  width: 75%;
}
.campaign-edit-img{
  width: 25%;
  float: right;
  padding: 25px 0 0;
}
.campaign-edit-img img{
  float: right;
}
.advert-content-header h4{
  font-size: 22px;
  color: #333333;
  font-weight: 500;
}
.advert-content-label{
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  text-transform: capitalize;
}
.advert-content-2{
  margin-top: 10px;
}
.advert-p{
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}
.advert-columns{
  margin-top: 15px;
}
.advert-columns .MuiGrid-item{
  margin-bottom: 13px;
}
.assets-video .advert-content-label{
  margin-bottom: 5px;
}
.assets-video .MuiGrid-grid-md-2{
  max-width: 18.667%;
  position: relative;
}
.assets-video .MuiGrid-grid-md-3{
  font-weight: 500;
  line-height: normal;
}
/* .assets-video .MuiGrid-container{
  margin:  0 -15px 0 -17px !important;
} */
.advert-icons-cover{
  text-align: center;
}
.advert-icons-cover svg{
  fill: #01A7A6;
}
.play-icon{
  position: absolute;
  top: calc(50% - 48px);
  left: 0;
  right: 0;
}
.play-icon svg{
  width: 2em;
  height: 2em
}
.vendors-list{
  margin-top: 10px;
  margin-bottom: 40px;
}
.vendors-list .MuiGrid-container{
  padding-left: 5px;
}
.claim-columns{
  margin-bottom: 20px
}
.vendors-list .MuiGrid-item{
  max-width: 164px;
  padding-right: 0 !important;
  padding-left: 10px !important;
  line-height: normal;
  font-weight: 500;
}
.vendors-list .MuiGrid-item:first-child{
  padding-left: 10px !important;
}
.vendors-list label{
  margin-bottom: 5px;
}
.vendor-btn{
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  font-family: "Roboto";
  background-color: #efebeb;
  padding: 5px 25px 5px 13px;
  border-radius: 4px;
  border: none;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.vendor-btn img{
  padding-left: 3px;
  position: absolute;
  top: 7px;
  right: 8px;
}
.claim-text{
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  /* padding-left: 15px; */
  width: 100%;
  margin-bottom: 15px;
  line-height: 28px;
}
.claim-text-detail {
  padding-left: 15px;
}
.claim-columns .MuiGrid-item {
  margin-bottom: 13px;
}
.add-btn-cover{
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}
.add-btn{
  background-color: #01A7A6;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  padding: 10px;
  width: 165px;
  border-radius: 4px !important;
  cursor: pointer;
}
.vendor-details-cover{
  float: left;
  display: block !important;
  border-bottom: 1px solid #e4e4e4 !important;
  padding: 10px 16px !important;
}
.vendor-details-cover .MuiListItemIcon-root{
  float: left;
  min-width: auto !important;
}
.vendor-details-cover .MuiListItemText-root{
  margin-top: 0;
  margin-bottom: 0;
}
.vendor-details-cover .MuiButtonBase-root .MuiIconButton-label svg{
  fill: none;
  background-image: url('../img/unchecked.png');
  background-repeat: no-repeat;
  transition: none !important;
}
.vendor-details-cover .MuiButtonBase-root.Mui-checked  svg{
  background-image: url('../img/checked.png');
  background-repeat: no-repeat;
}
.vendor-details-cover .MuiCheckbox-colorSecondary.Mui-checked:hover,
.vendor-details-cover .MuiIconButton-colorSecondary:hover{
  background-color: inherit !important;
}
.vendor-id{
  float: left;
  width: 50%;
  margin-top: 4px !important;
}
.vendor-id span{
  font-size: 13px;
  color: #999999;
  text-transform: uppercase;
  font-weight: 400;
}
.vendor-name{
  float: left;
  width: 50%;
}
.vendor-name span{
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}
.tab-list-cover{
  float: left;
  max-width: 100% !important;
  padding: 8px 20px !important;
}
/* Vendor Page CSS */
.custom-container{
  padding: 0 30px;
}
.img-preview {
  display: block;
  width: auto;
  height: 100%;
  /* max-width: 200px; */
  max-height: 200px;
}
.img-preview-ad-detail {
  display: block;
  width: 100%;
  height: 121px;
  /* max-width: 200px; */
  max-height: 121px;
}
.thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16
}
.text-center{
  text-align: center
}
.display-block {
  display: block;
  width: 100%;
}
.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  /* margin-bottom: 8px;
  margin-right: 8px; */
  /* width: 100px;
  height: 100px; */
  /* padding: 4px; */
  box-sizing: border-box
}

.thumb-inner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
  margin: auto;
}
.custom-container .MuiGrid-grid-md-4{
  padding: 0 12px !important;
}
.profile-email .MuiInputBase-root{
  color: rgba(0,0,0,0.7);
}
/* Change Password Dialog */
.change-password-dialogue{
  max-width: inherit;
  width: 250px;
}
.change-password-dialogue .MuiFormGroup-root{
  margin-bottom: 0px !important;
}

.change-password-dialogue .form-button-cover{
  margin-top: 0 !important;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 8px 16px;
}
.change-password-dialogue  .MuiFormControl-root {
  width: 100%;
}
.profile-role{
  /* margin-top: 13px; */
  padding-top: 3px;
}
.profile-img-button{
  padding-top: 6px !important;
  padding-bottom: 0px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.profile-content .add-advert-form .MuiFormControl-root{
  width: 100%;
}
.adnetwork .error-class,.createcampaign .error-class{
  padding-top: 5px !important;
}
.pop-check{
  width: 21px;
  height: 21px;
  margin-right: 3px;
}

.advertdetails-cover .MuiGrid-grid-md-12{
  flex-basis: auto;
}
.advert-columns .MuiGrid-grid-md-12{
  flex-basis: 100%;
}
.search-btn-cover{
  float: left;
  width:400px;
  position: relative;
  padding: 25px 0;
}


.filterby-btn-cover{
  float: left;
  width: 80px;
  position: relative;
  padding: 34px 0;
  margin-left: 30px;
  margin-right: -80px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
.status-btn-cover{

  float: left;
  position: relative;
  padding: 8px 0;
  margin-right: -25px;
}
.filter-button-cover{
  float: left;
  padding: 10px 0;
  margin-left: 40px;
}
.search-btn{
  bottom: 0;
  color: #333333;
  font-size: 15px;
  line-height: 36px;
  width: 100%;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  padding-inline-start: 40px;
  vertical-align: middle;
  white-space: nowrap;
  border: none;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  border: 1px solid rgba(32, 33, 36, .28) !important;
  cursor: pointer;
}
.adsearch-btn{
  bottom: 0;
  color: #333333; 
  line-height: 36px;
  width: 100%;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  padding-inline-start: 10px;
  vertical-align: middle;
  white-space: nowrap;
  border: none;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  border: 1px solid rgba(32, 33, 36, .28) !important;
  cursor: pointer;
}
.radius-btn{
  bottom: 0;
  color: #333333; 
  
  width: 100%;
  
  text-align: initial;
  text-overflow: ellipsis;
  padding-inline-start: 40px;
  vertical-align: middle;
  white-space: nowrap;
  border: none;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  border: 1px solid rgba(32, 33, 36, .28) !important;
  cursor: pointer;
}
.filter-btn{
  bottom: 0;
  color: #333333;
  font-size: 15px;
  line-height: 36px;
  width: 150px;
  overflow: hidden;
  text-align: initial;
  text-overflow: ellipsis;
  padding-inline-start: 10px;
  vertical-align: middle;
  white-space: nowrap;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
  border: 2px solid rgba(32, 33, 36, .28) !important;
  cursor: pointer;
}
.search-btn + i{
  position: absolute;
  line-height: 35px;
  margin-top: 2px;
  left: 15px;
}
.del-btn{
  min-width: auto !important;
  padding: 0 !important;
  top: -3px;
  margin-right: 6px !important;
}
.del-btn .MuiButton-label .MuiIcon-root{
  width: auto;
  height: auto;
  font-size: inherit;
  line-height: inherit;
}
.MuiInput-formControl input, .MuiInput-formControl select{
  line-height: 19px !important;
}
.profile-box .MuiTouchRipple-root{
  display: none;
}
/* Select Box Css */
.MuiSelect-selectMenu{
  font-weight: 400 !important;
}
.signup-zip .MuiInput-formControl{
  margin-top: 12px !important;
}
.image-ref-group .MuiInput-formControl{
  margin-top: 15px !important;
}
.image-ref-group .MuiInputLabel-formControl{
  top: 2px;
}



.addNetworkBtnCover button.buttonlinkHeader {
  padding-left: 12px;
}
.addNetworkBtnCover .drop-btn {
  margin-top: 12px !important;
}
.setPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 10px 18px 0 !important;
}
.adverttabs-header .active-tab::before {
  background-color: #01A7A6;
}
.MuiTableRow-root.MuiTableRow-hover.selectedAdAnalytics,
.MuiTableRow-root.MuiTableRow-hover.selectedAssetAnalytics {
  background: linear-gradient(60deg, #01A7A6, #01A7A6) !important;
  color: #fff !important;
}
.MuiTableRow-root.MuiTableRow-hover.selectedAdAnalytics td,
.MuiTableRow-root.MuiTableRow-hover.selectedAssetAnalytics td {
  color: #fff !important;
}
.text-select {
	font-weight: 500;
	border-left: 3px solid #01A7A6;
	background-color: #f6f6f6;
	padding: 10px 20px 10px 10px;
	margin: 10px 0;
	display: inline-block;
}
.MuiSelect-root .MuiMenuItem-gutters {
  line-height: 18px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
.customTooltipClass {
  font-size: 14px;
  padding:5px 5px;
  text-align: center;
  display: block;
  line-height: 1.2;
}
.textalign-right {
  text-align: right;
}
.ad-back-btn {
  border-radius: 4px;
	font-size: 15px;
	color: #fff !important;
	text-transform: uppercase;
	background-color: #01A7A6;
	border: none;
  padding: 7px 5px;
  min-width: 90px !important;
  text-align: center;
  display: inline-block;
  margin-right: -15px;
}

/*UI Changes Start*/
.patient-list-page.patient-list-page-new .list-item-content > div,  .find-provider-tabs .patient-info-page-new .divcontainer-tab{-ms-flex-align: center; align-items: center;}
.patient-list-page.patient-list-page-new .list-item-content{padding: 10px 0 10px 15px;}
.patient-list-page.patient-list-page-new .owl-carousel{padding:0 24px;}
.patient-list-page.patient-list-page-new .owl-item-box, .patient-info-page-new.AwaitingProviderTabPanelCSS .menu-item{max-width: inherit;}
/* .patient-list-page-new.AwaitingProviderTabPanelCSS .category-type{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding: 8px; width: 100%;} */
.patient-list-page-new.AwaitingProviderTabPanelCSS .category-type{padding:2px 5px;width:100%;word-break:break-all;word-break:break-word;white-space:normal;line-height:normal;}
.patient-list-page.patient-list-page-new .owl-theme .owl-nav{margin-top:0;}
.patient-list-page-new .owl-theme .owl-nav [class*='owl-']{margin-top:0;}
.patient-list-page-new .owl-carousel .owl-nav button.owl-prev{margin-left:0;}
.rightTabPanelMain .btn1{text-align:center;}
.find-provider-tabs .patient-info-page-new .user-info-tab{padding:10px 0;}
.alignCenterTextCenter{display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;-ms-flex-align: center; align-items: center;}
.alignCenterTextCenter .show-text{padding-left: 10px;margin-top: 2px;}




/* Modal Fit On Screen*/
.MuiPickersModal-dialogRoot .MuiDialogContent-root:first-child{padding-top:0 !important;}
.modalFitOnScreen.front-modal .add-modal-title{padding:8px 20px;}
.modalFitOnScreen.front-modal .add-modal-title button{padding:7px;}
.modalFitOnScreen.front-modal .MuiDialogContent-root,.modalFitOnScreen.front-modal .MuiDialogActions-root{padding:15px 20px 0 !important;margin:0 !important;}
.modalFitOnScreen.front-modal .MuiDialogActions-root{padding:15px 20px 25px !important;}
.modalFitOnScreen.front-modal .btn1 .MuiTypography-colorPrimary > i{margin-right:5px;}
.modalFitOnScreen.front-modal .form-caption{margin-bottom:10px;}
.modalFitOnScreen.front-modal .chipinput{margin:0 10px 10px 0 !important;}
.modalFitOnScreen.front-modal button:disabled{opacity:.7;}
.modalFitOnScreen.front-modal .upload-links{margin:5px 0;}
.modalFitOnScreen.front-modal .upload-links ul{padding:0;margin:0;position:relative;width:100%;display:inline-block;font-size:13px;}
.modalFitOnScreen.front-modal .upload-links ul .pemoveAllLink{position:absolute;top:-33px;left:115px;cursor:pointer;background-color:#f9c6c6;color:#863232;padding:3px 20px 3px 15px;border-radius:30px;}
.modalFitOnScreen.front-modal .upload-links ul li{display:block;width:100%;}
.modalFitOnScreen.front-modal .upload-links ul .MuiListItem-root{padding:1px;}
.modalFitOnScreen.front-modal .upload-links ul .MuiListItem-root.MuiListItem-secondaryAction{padding-right:30px;}
.modalFitOnScreen.front-modal .upload-links ul .MuiListItem-root .MuiTypography-body1{font-size:12px;}
.modalFitOnScreen.front-modal .upload-links ul .pemoveAllLink .MuiIconButton-root,.modalFitOnScreen.front-modal .upload-links ul li .MuiIconButton-root{padding:4px;}
.modalFitOnScreen.front-modal .upload-links ul .pemoveAllLink .MuiIconButton-root .MuiSvgIcon-root,.modalFitOnScreen.front-modal .upload-links ul li .MuiIconButton-root .MuiSvgIcon-root{font-size:16px;color:#a95d5d;}
.modalFitOnScreen.front-modal .uploadFilesList{margin:5px 0 0;}
.modalFitOnScreen.front-modal .upload-links ul li .MuiIconButton-root .MuiSvgIcon-root{ font-size:18px;}
.modalFitOnScreen.front-modal .upload-links ul .MuiListItem-root.MuiListItem-secondaryAction .MuiListItemText-root{margin:0;}
.modalFitOnScreen.front-modal .upload-links .Mui-error + ul span.pemoveAllLink{top:-45px;}
.modalFitOnScreen.front-modal .patient-name{margin-top:0;}
.modalFitOnScreen.front-modal .upload-links .btn1{padding:0 5px !important;}
.modalFitOnScreen.front-modal .upload-links .MuiButton-label + .MuiTouchRipple-root:empty{display:none;}


/*Patient Info Page*/
.patient-list-page.patient-info-page-new .list-item-content{padding:0px 0 0px 15px;}
.patient-info-page-new.patient-list-page .box-filter{margin:3px 0 0;width:140px;padding:0;}
.withoutCheckboXContent{padding-left:16px;}
.patient-info-page-new.patient-list-page .search-box-cover{border-radius:6px !important;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .owl-carousel .owl-item{padding:10px 5px;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .owl-stage{display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:nowrap;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .owl-item{display:-webkit-flex;display:-ms-flexbox;display:flex;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .menu-item{box-shadow:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height: 66px;background-color: #e9f3fd;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .tag{line-height:32px;min-width:90px;width:auto;padding:0 10px;cursor:pointer;margin-left:5px;}
.AwaitingProviderTabPanelCSS.FindProviderTab .OwlCarousel-box{min-width:150px;width:calc(100% - 150px);}
.patient-info-page-new.AwaitingProviderTabPanelCSS .menu-item{margin-right: 0; }
.patient-info-page-new.AwaitingProviderTabPanelCSS .box-filter .MuiInputLabel-shrink{color: #FFF !important;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .divcontainer-tab .btn4{padding: 0 10px !important;line-height: normal !important;}
.patient-info-page-new.patient-list-page .refBoxInMiddle .box-filter{white-space:nowrap;display:inline-block;width:auto;margin:0 auto;}
.patient-info-page-new.patient-list-page .refBoxInMiddle .box-filter > div{white-space:nowrap;display:inline-block;vertical-align:middle;}
.patient-list-page .refBoxInMiddle .box-filter .MuiOutlinedInput-input{width:90px;}
.right-box-cover .patientName{margin: 0 auto;}
.patient-list-page.patient-list-page-new .list-item-content{border: 2px solid #FFF;}
.patient-list-page.patient-list-page-new .list-item-content.selectedlist{border-color: #2680EB;}
.right-box-cover .right-box-content{min-height: calc(100vh - 352px);position: relative;}
.right-box-cover .middle-section{min-height: 100%;}
.right-box-cover .middle-section-content.middle-section-content-middle{position:absolute;left:50%;top:50%;margin-top:20px;transform:translate(-50%,-50%);width:100%;}
.right-box-cover .middle-section-content.middle-section-content-middle .text{margin:0 auto;}
.loaderProgressFixed, .LinearProgress-ref{position:absolute;top:50px;left:0;width:100%;}
.top65Fixed.loaderProgressFixed{top: 65px;}







/* Responsive CSS */
@media (max-width: 1199px){
.patient-list-page.patient-list-page-new .owl-carousel{padding:0 30px;}
.patient-list-page.patient-list-page-new .owl-next{right:0;}
.patient-list-page.patient-list-page-new .owl-prev{left:0;}
.patient-info-page-new.FinalisedTab .tag-finalised{width:165px;}
.patient-info-page-new.FinalisedTab .OwlCarousel-box-finalised{width:calc(100% - 320px);}
.patient-info-page-new.FinalisedTab .user-info-tab-finalised{min-width: 140px !important;}
/* .rightTabPanelMain .react-tabs .react-tabs__tab-panel{height:calc(100vh - 482px);} */
/* .patient-info-page-new .YOnlytScrollbars {height: calc(100vh - 555px) !important;} */
.patient-info-page-new .rightTabPanelMain .btn1{text-align:center;}
.patient-info-page-new .rightTabPanelMain .btn1 .MuiTouchRipple-root:empty{display:none;}  
.patient-info-page-new .find-provider-tabs .divcontainer-tab .action-box-tab > div:first-child{padding-left:0;}
.patient-info-page-new .btn4{font-size:10px !important;padding:0 12px !important;min-width:90px !important;line-height:normal !important;height:34px !important;}
.patient-info-page-new.patient-list-page .box-filter{width:120px;}
.patient-info-page-new.FinalisedTab .tag-referral{font-size:11px;line-height:normal;}
.patient-info-page-new.patient-list-page .search-box-cover input{width:130px;}
.patient-list-page-new.AwaitingProviderTabPanelCSS .category-type{font-size: 12px;}
.patient-info-page-new.patient-list-page .name{margin:5px auto 0;font-size:13px;}
.patient-info-page-new.patient-list-page .date-insurance{line-height:normal;}
.rightTabPanelMain .details-section .btns-cover{align-items:center;margin-top:.65rem;}
.rightTabPanelMain .details-section .btns-cover .btn1{width:100%;}
.patient-list-page .action-box.patientListFilters{flex-wrap:nowrap;flex-flow:nowrap;justify-content:space-between;padding:8px 0;}
.patient-list-page .patientListFilters .box-filter{width:140px;}
.patient-list-page .box-filter.filter-orderby {
  width: 180px;
}
.provider-tab li{padding: 10px 12px;}


}
@media (max-width:991px){
/* .rightTabPanelMain .react-tabs .react-tabs__tab-panel{height:calc(100vh - 625px);} */
/* .patient-info-page-new .YOnlytScrollbars{height:calc(100vh - 690px) !important;} */
.patient-list-page.patient-list-page-new .list-item-content > div > div .tags-cover{margin:0 auto;}
.patient-list-page.patient-list-page-new .list-item-content > div > div.tag-finalised{width:auto !important;}
.siglePatientPageMain .left-list .patient-info-cover .patient-info{box-shadow:none;}
.patient-info-page-new.patient-list-page .list-item-content > div > div{margin-top:5px;margin-bottom:5px;}
.patient-info-page-new.patient-info-page-new.AwaitingProviderTabPanelCSS .owl-carousel .owl-item{padding-top:0;padding-bottom:5px;}
.patient-info-page-new.patient-list-page .name{margin:0;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .phoneNumber{vertical-align:middle;}
.name-status-tag .name{font-size:1rem;}
.ProviderFind .titlename{font-size:1.2rem;}
.ProviderFind .titlename > img{max-width:14px;}
.patient-info-page-new.AwaitingProviderTabPanelCSS .tag{margin:0 5px;}
.find-provider-tabs .patient-info-page-new .user-info-tab, .find-provider-tabs .patient-info-page-new .user-info-tab-finalised{min-width:100% !important;text-align:center;display:flex;align-items:center;justify-content:center;}
.find-provider-tabs .patient-info-page-new .user-info-tab > div, .find-provider-tabs .patient-info-page-new .user-info-tab-finalised > div{padding:0 10px;}
.find-provider-tabs .patient-info-page-new .user-info-tab > div + div, .find-provider-tabs .patient-info-page-new .user-info-tab-finalised > div + div{border-left:1px solid #CCC;}
.patient-list-page .date-insurance svg{vertical-align:middle;max-width:12px;max-height:12px;}



}
@media (max-width: 849px){
.patient-list-page.patient-list-page-new .name-status-task-insurance{margin: 0 auto;}
.patient-list-page.patient-list-page-new .list-item-content > div > div{text-align: center;padding: 0;}
.patient-list-page.patient-list-page-new .list-item-content > div > div:last-child{margin-top: 0;}
.patient-list-page.patient-list-page-new .list-item-content{padding: 0 !important;}
.patient-list-page.patient-list-page-new .owl-carousel .owl-stage{margin:0 auto;}
}




/* End Of UI Chnages */



/*Responsive*/
@media (max-width: 1600px){
  .assets-video .MuiGrid-grid-md-2{
    max-width: 29.667%;
  }
}
@media (max-width: 1300px){
  .dash-tiles h3{
    font-size: 18px;
  }
  .profile-content form .MuiGrid-grid-md-3{
    max-width: 30%;
    flex-basis: 30%;
  }
}
@media (max-width: 1199px){
  /* .dash-tiles{
    min-height: 138px;
  } */
  .dash-tiles p{
    line-height: 20px;
  }
  .dash-tiles.advertisement p{
    font-size: 14px !important;
    padding: 0 12px !important;
  }
  .advert-details{
    padding: 0 12px;
  }
  .assets-count, .vendor-count, .claims-count{
    margin-right: 16px;
  }
  .grey-text{
    margin: 0 8px;
  }
  .vendor-count{
    margin-right: 0;
  }
  .vendor-id{
    width: 65%;
  }
  .vendor-name{
    width: 65%; 
  }
  .vendor-name span{
    font-size: 15px;
  }
  .add-advert-form{
    width: 30%;
  }
  .assets-video .MuiGrid-grid-md-3 img{
    width: 100%;
  }
  .assets-video .MuiGrid-grid-md-3{
    padding-right: 0px !important;
  }
}
@media (max-width: 1024px){
  .grey-text{
    font-size: 13px;
  }
  .assets-count, .vendor-count, .claims-count{
    margin-right: 11px;
  }
  .vendor-count{
    margin-right: 0;
  }
  .assets-video .MuiGrid-grid-md-2{
    max-width: 32.667%;
  }
  .vendor-id{
    width: 75%;
  }
  .vendor-name{
    width: 75%; 
  }
  .profile-content form .MuiGrid-grid-md-3{
    max-width: 35%;
    flex-basis: 35%;
  }
  .createcampaign .MuiGrid-grid-md-3{
    max-width: 35%;
    flex-basis: 35%;
  }
}
@media (max-width: 992px){
  /* .dash-tiles{
    min-height: 160px;
  } */
  .dash-tiles p{
    font-size: 13px;
  }
  .adnetwork .MuiGrid-grid-xs-6{
    max-width: 50%;
    flex-basis: 50%;
  }
  .assets-count, .vendor-count, .claims-count{
    margin-right: 14px;
  }
  .dash-tiles.advertisement{
    min-height: 104px;
  }
  .profile-content form .MuiGrid-grid-md-3{
    max-width: 33%;
    flex-basis: 33%;
  }
}
@media (max-width: 959.95px){
  /* .sidebar-cover { 
    left :0px !important;
    transform: none !important; 
  }
  .sidebar-outer-cover{
    display: block !important;  
  }
  .Dashboard-mainPanel-2 {
    width: calc(100% - 293px) !important;
  } */
  /* .MuiIconButton-label{
    color: #999999;
  } */
  /* .profile-box{
    margin-top: 30px;
    padding: 0 5px;
  } */
  .profile-box .username-link{
    color: #fff !important;
    text-underline: none !important;
  }
  .profile-box button{
    display: inline-block;
    width: auto;
    padding: 0 12px;
    margin: 0;
  }
  /* .profile-box a{
    width: calc(100% - 60px) !important;
    margin-top: 2px !important;
  } */
  .profile-box button span{
    float: left;
  }
  .profile-box button span span img{
    top: 0 !important;
  }
  .back-btn{
    padding: 0px 0px !important;
    min-width: 85px !important;
  }
  .addNetworkBtnCover ul.MuiList-root.MuiList-padding li {
    margin: 0px 15px 0 !important;
    padding: 5px 15px !important;
  }
}
@media (max-width: 767px) { 
  html {
    font-size: 12px !important;
  }
  .add-modal > .MuiBox-root {
    flex-direction: column;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 10px !important;
  }
  .profile-content .MuiBox-root {
    width: 100%;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .search-btn-cover {
    max-width: 100%;
  }
}
@media (max-width: 768px){
  .login-inner-cover {
    width: 450px;
    margin: 20px auto;
  }
  .green-header{
    width: 440px;
    padding: 15px;
    top: 35px;
  }
  .adnetwork .MuiGrid-grid-xs-6{
    max-width: 50%;
    flex-basis: 50%;
  }
  .claims-count{
    margin-right: 0;
  }
  .add-advert-form{
    width: 45%;
  }
  .add-advert-form50{
    width: calc(90% + 30px);
  }
  .advertdetails-cover .MuiGrid-grid-md-8{
    max-width: 100%;
    flex-basis: 100%;
    order: 2;
    margin-top: 15px;
  }
  .advertdetails-cover .MuiGrid-grid-md-4{
    max-width: 100%;
    flex-basis: 100%;
    order: 1;
  }
  .vendor-details-cover{
    padding: 8px 16px !important;
  }
  .advertdetail-small-cover{
    padding: 0 40px;
    height: 350px;
    overflow: hidden;
  }
  .vendor-btn{
    font-size: 13px;
  }
  .vendors-list .MuiGrid-item{
    margin-bottom: 10px;
  }
  .adverttabs-header + div{
    overflow-y: hidden !important;
    height: calc(100% - 75px) !important;
  }
  .adverttabs-header + div > div > ul{
    overflow-y: auto !important;
    height: 100%;
  }
  .adverttabs-header + div > div {
      padding-bottom: 55px;
      height: 100% !important;
  }
  .profile-content form .MuiGrid-grid-md-3{
    max-width: 45%;
    flex-basis: 45%;
  }
  .createcampaign .MuiGrid-grid-md-3{
    max-width: 45%;
    flex-basis: 45%;
  }
  .advertdetails-cover .MuiGrid-grid-md-4 {
    padding-left: 15px !important; 
  }
}
@media (max-width: 580px){
  .login-inner-cover{
    width: 450px;
  }
  .green-header{
    width: 100%;
  }
  .adnetwork .MuiGrid-grid-xs-6 {
      max-width: 100%;
      flex-basis: 100%;
  }
  .profile .MuiGrid-grid-xs-6{
    max-width: 100%;
    flex-basis: 100%;
  }
  .createcampaign .MuiGrid-grid-xs-6{
    max-width: 100%;
    flex-basis: 100%;
  }
  .campaign-id h5{
    font-size: 15px;
  }
  .advert-content-header h4{
    font-size: 18px;
  }
  .vendors-list .MuiGrid-item{
    margin-bottom: 10px
  }
  .vendor-btn{
    padding: 8px 12px;
  }
  .vendors-list .MuiGrid-item{
    padding-left: 15px !important;
  }
  .vendor-btn img{
    position: relative;
    top: 1px;
  }
  .campaign-edit-img{
    padding: 23px 0 0 ;
  }
  .campaign-edit-img img{
    width: 22px;
  }
  .createcampaign .MuiGrid-grid-md-3{
    max-width: 45%;
    flex-basis: 45%;
  }
  

  .MuiTablePagination-actions .MuiIconButton-root{
    padding: 0;
  }
  .MuiTablePagination-root .MuiToolbar-gutters {
    padding-left: 0;
  }
  .btn1,.btn2,.btn3 {
    min-width: 80px !important;
  }
}
@media (max-width: 460px){
  html {
    font-size: 12px;
  }
  .login-content {
    padding: 30px 15px 15px;
  }
  .d-flex.justify-space-evenly {
    flex-direction: column;
  }

  .MuiTablePagination-root .MuiToolbar-gutters {
    padding-left: 0;
    flex-direction: column;
    height: auto;
    width: auto;
    min-width: auto;
    min-height: auto;
    padding-right: 0;
    margin-top: 10px;
  }
  .MuiTypography-caption {
    font-size: 1rem !important; 
  }
  .MuiTablePagination-actions {
    margin-left: 0 !important;
  }
  
  .login-content .MuiGrid-grid-xs-6{
    max-width: 100%;
    flex-basis: 100%
  }
  .login-inner-cover{
    width: 90%;
  }
  .green-header{
    width: 100%;
  }
  .login-content a, .login-content a:hover{
    font-size: 14px;
    margin-top: 2px;
    font-weight: 400;
  }
  .form-button-cover .MuiButton-root{
    padding: 5px 10px !important;
    margin-bottom: 15px;
  }
  .form-button-cover .MuiButton-root span{
    font-size: 14px;
  }
  .form-button-cover .back-btn{
    padding: 0px 0px !important;
  }
  .add-advert-form{
    width: 100%;
  }
  .add-advert-form50{
    width: 100%;
  }
  .assets-video .MuiGrid-grid-md-2{
    max-width:40%;
    margin-bottom: 10px;
  }
  .campaign-edit-img img{
    width: 20px;
  }
  .back-btn span{
    line-height: 32px;
  }
  .back-btn{
    min-width: 75px !important;
  }
  .login-forgot button.back-btn{
    min-width: 70px !important;
  }
  /* .login-outer-cover button.MuiButtonBase-root{
    padding: 4px 8px;
  } */
  .profile-content form .MuiGrid-grid-md-3{
    max-width: 100%;
    flex-basis: 100%;
  }
  .login-forgot button{
    padding: 5px 0 !important;
  }
 
  .search-btn + i{
    left:7px;
  }
  .search-btn{
    left: 30px;
    padding-inline-start:30px;
  }
  .assets-video .MuiGrid-grid-xs-6{
    max-width: 100%;
    flex-basis: 100%;
  }
  .selectedAdnetwork{width:100%}
  .selectedAdnetwork span{display: block;}
}
@media (max-width: 375px){
  .login-forgot button.MuiButtonBase-root span.MuiButton-label{
    /* margin-top: 10px; */
    /* padding: 4px 22px !important; */
    padding: 4px 8px !important;
  }
  .login-forgot button.back-btn span.MuiButton-label{
    padding: 0 !important;
  }
  .assets-video .MuiGrid-grid-md-2{
    max-width: 50%;
  }
  .play-icon{
    left: calc(50% - 63px);
  }
  .login-forgot button.back-btn{
    min-width: 70px !important;
  }
  .search-btn-cover{
    margin-bottom: 15px;
  }
}
@media (max-width: 320px){
  .play-icon {
      left: calc(50% - 48px);
  }
}
.new-modal {
  position: relative;
}
.preloader {
  position: relative;
  width: 200px;
  margin: 6em auto;
}
.preloader span.loader_span {
  position: absolute;
  display: block;
  bottom: 0;
  width: 9px;
  height: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: preloader 2s infinite ease-in-out;
          animation: preloader 2s infinite ease-in-out;
}
.preloader span.loader_span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.preloader span.loader_span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.preloader span.loader_span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}
.preloader span.loader_span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms;
}
.preloader span.loader_span:nth-child(6) {
  left: 55px;
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}
.preloader span.loader_span:nth-child(7) {
  left: 66px;
  -webkit-animation-delay: 1200ms;
          animation-delay: 1200ms;
}
.preloader span.loader_span:nth-child(8) {
  left: 77px;
  -webkit-animation-delay: 1400ms;
          animation-delay: 1400ms;
}
.preloader span.loader_span:nth-child(9) {
  left: 88px;
  -webkit-animation-delay: 1600ms;
          animation-delay: 1600ms;
}
.preloader span.loader_span:nth-child(10) {
  left: 99px;
  -webkit-animation-delay: 1800ms;
          animation-delay: 1800ms;
}
.preloader span.loader_span:nth-child(11) {
  left: 110px;
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
}
.preloader span.loader_span:nth-child(12) {
  left: 121px;
  -webkit-animation-delay: 2200ms;
          animation-delay: 2200ms;
}
.preloader span.loader_span:nth-child(13) {
  left: 132px;
  -webkit-animation-delay: 2400ms;
          animation-delay: 2400ms;
}
.preloader span.loader_span:nth-child(14) {
  left: 143px;
  -webkit-animation-delay: 2600ms;
          animation-delay: 2600ms;
}
.preloader span.loader_span:nth-child(15) {
  left: 154px;
  -webkit-animation-delay: 2800ms;
          animation-delay: 2800ms;
}
.preloader span.loader_span:nth-child(16) {
  left: 165px;
  -webkit-animation-delay: 3000ms;
          animation-delay: 3000ms;
}
.preloader span.loader_span:nth-child(17) {
  left: 176px;
  -webkit-animation-delay: 3200ms;
          animation-delay: 3200ms;
}
.preloader span.loader_span:nth-child(18) {
  left: 187px;
  -webkit-animation-delay: 3400ms;
          animation-delay: 3400ms;
}
.preloader span.loader_span:nth-child(19) {
  left: 198px;
  -webkit-animation-delay: 3600ms;
          animation-delay: 3600ms;
}
.preloader span.loader_span:nth-child(20) {
  left: 209px;
  -webkit-animation-delay: 3800ms;
          animation-delay: 3800ms;
}
.preloader span.loader_span:nth-child(21) {
  left: 220px;
  -webkit-animation-delay: 4000ms;
          animation-delay: 4000ms;
}
.preloader span.loader_span:nth-child(22) {
  left: 231px;
  -webkit-animation-delay: 4200ms;
          animation-delay: 4200ms;
}
.preloader span.loader_span:nth-child(23) {
  left: 242px;
  -webkit-animation-delay: 4400ms;
          animation-delay: 4400ms;
}
.preloader span.loader_span:nth-child(24) {
  left: 253px;
  -webkit-animation-delay: 4600ms;
          animation-delay: 4600ms;
}
.preloader span.loader_span:nth-child(25) {
  left: 264px;
  -webkit-animation-delay: 4800ms;
          animation-delay: 4800ms;
}
.preloader span.loader_span:nth-child(26) {
  left: 275px;
  -webkit-animation-delay: 5000ms;
          animation-delay: 5000ms;
}

@-webkit-keyframes preloader {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    background: #3498db;
  }
  50%,
	100% {
    height: 5px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
}

@keyframes preloader {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    background: #01A7A6;
  }
  50%,
	100% {
    height: 5px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_loader {
  position: absolute;
  top: 60%;
}
.download_loader {
  position: absolute;
}